import { Switch } from '@app/@/components/ui/switch'
import { useWebsiteContext } from '@app/components/providers/website'
import { Website } from '@app/types'

export const StepFive = ({
  active,
  website,
}: {
  active: boolean
  website?: Website
}) => {
  const { addWebsiteForm, updateWebsite } = useWebsiteContext()

  const onChangeWebsiteVisiblity = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          profileVisible: !website?.profileVisible,
        },
      })
    }
  }

  const onChangeEvent = async () => {
    if (website) {
      await onChangeWebsiteVisiblity()
    } else {
      addWebsiteForm.onChangeWebsiteVisiblity()
    }
  }

  return (
    <div className={`px-5 h-full${!active ? ' hidden' : ''}`}>
      <div className='flex items-center gap-5 flex-wrap py-12'>
        <div className='flex-1'>
          {website ? null : (
            <div className='flex gap-3 items-center'>
              <div className='rounded bg-yellow-500 p-2 flex items-center place-content-center'>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
                  className='fill-white'
                >
                  <path d='M8.67 22.75H2C1.59 22.75 1.25 22.41 1.25 22V16C1.25 14.48 2.48 13.25 4 13.25H8.67C9.08 13.25 9.42 13.59 9.42 14V22C9.42 22.41 9.08 22.75 8.67 22.75ZM2.75 21.25H7.92V14.75H4C3.31 14.75 2.75 15.31 2.75 16V21.25Z' />
                  <path d='M15.3302 22.75H8.66016C8.25016 22.75 7.91016 22.41 7.91016 22V12C7.91016 10.48 9.14016 9.25 10.6602 9.25H13.3302C14.8502 9.25 16.0802 10.48 16.0802 12V22C16.0802 22.41 15.7502 22.75 15.3302 22.75ZM9.42015 21.25H14.5902V12C14.5902 11.31 14.0302 10.75 13.3402 10.75H10.6702C9.98015 10.75 9.42015 11.31 9.42015 12V21.25Z' />
                  <path d='M22.0001 22.75H15.3301C14.9201 22.75 14.5801 22.41 14.5801 22V17C14.5801 16.59 14.9201 16.25 15.3301 16.25H20.0001C21.5201 16.25 22.7501 17.48 22.7501 19V22C22.7501 22.41 22.4101 22.75 22.0001 22.75ZM16.0801 21.25H21.2501V19C21.2501 18.31 20.6901 17.75 20.0001 17.75H16.0801V21.25Z' />
                  <path d='M13.6999 8.35023C13.4599 8.35023 13.1599 8.28021 12.8199 8.08021L11.9999 7.59022L11.1899 8.07023C10.3699 8.56023 9.82989 8.27023 9.62989 8.13023C9.42989 7.99023 8.99989 7.55023 9.20989 6.63023L9.39989 5.80021L8.71989 5.12022C8.29989 4.70022 8.14989 4.20021 8.29989 3.74021C8.44989 3.28021 8.85989 2.96021 9.43989 2.86021L10.3099 2.71021L10.7999 1.73023C11.3399 0.660234 12.6499 0.660234 13.1799 1.73023L13.6699 2.71021L14.5399 2.86021C15.1199 2.96021 15.5399 3.28021 15.6799 3.74021C15.8299 4.20021 15.6699 4.70022 15.2599 5.12022L14.5799 5.80021L14.7699 6.63023C14.9799 7.56023 14.5499 7.99024 14.3499 8.14024C14.2599 8.22024 14.0299 8.35023 13.6999 8.35023ZM11.9999 6.08021C12.2399 6.08021 12.4799 6.14023 12.6799 6.26023L13.2399 6.59022L13.1199 6.05021C13.0199 5.63021 13.1699 5.12022 13.4799 4.81022L13.9899 4.30021L13.3599 4.19023C12.9599 4.12023 12.5699 3.83022 12.3899 3.47022L11.9999 2.72022L11.6199 3.47022C11.4399 3.83022 11.0499 4.12023 10.6499 4.19023L10.0199 4.29023L10.5299 4.80021C10.8399 5.11021 10.9799 5.62023 10.8899 6.04023L10.7699 6.58021L11.3299 6.25022C11.5199 6.13022 11.7599 6.08021 11.9999 6.08021Z' />
                </svg>
              </div>
              <h3 className='text-xl font-semibold'>
                Publish Your Site to the Leaderboard!
              </h3>
            </div>
          )}

          <div className='py-4'>
            <span className='text-sm text-muted-foreground'>
              By enabling this option:
            </span>
          </div>

          <ul className='space-y-2'>
            <li className='flex gap-3 items-center'>
              <div>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z'
                    fill='#020817'
                  />
                  <path
                    d='M15.5 10.5C14.26 10.5 13.25 9.49 13.25 8.25C13.25 7.01 14.26 6 15.5 6C16.74 6 17.75 7.01 17.75 8.25C17.75 9.49 16.74 10.5 15.5 10.5ZM15.5 7.5C15.09 7.5 14.75 7.84 14.75 8.25C14.75 8.66 15.09 9 15.5 9C15.91 9 16.25 8.66 16.25 8.25C16.25 7.84 15.91 7.5 15.5 7.5Z'
                    fill='#020817'
                  />
                  <path
                    d='M8.5 10.5C7.26 10.5 6.25 9.49 6.25 8.25C6.25 7.01 7.26 6 8.5 6C9.74 6 10.75 7.01 10.75 8.25C10.75 9.49 9.74 10.5 8.5 10.5ZM8.5 7.5C8.09 7.5 7.75 7.84 7.75 8.25C7.75 8.66 8.09 9 8.5 9C8.91 9 9.25 8.66 9.25 8.25C9.25 7.84 8.91 7.5 8.5 7.5Z'
                    fill='#020817'
                  />
                  <path
                    d='M12 19.4498C9.1 19.4498 6.75 17.0898 6.75 14.1998C6.75 13.2898 7.49 12.5498 8.4 12.5498H15.6C16.51 12.5498 17.25 13.2898 17.25 14.1998C17.25 17.0898 14.9 19.4498 12 19.4498ZM8.4 14.0498C8.32 14.0498 8.25 14.1198 8.25 14.1998C8.25 16.2698 9.93 17.9498 12 17.9498C14.07 17.9498 15.75 16.2698 15.75 14.1998C15.75 14.1198 15.68 14.0498 15.6 14.0498H8.4Z'
                    fill='#020817'
                  />
                </svg>
              </div>
              <p className='text-muted-foreground text-sm'>
                <strong className='text-black dark:text-white font-bold'>
                  Gain Visibility
                </strong>{' '}
                Be featured on our prestigious leaderboard, showcasing your
                commitment to web accessibility.
              </p>
            </li>
            <li className='flex gap-3 items-center'>
              <div>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M6.87988 18.9001C6.46988 18.9001 6.12988 18.5601 6.12988 18.1501V16.0801C6.12988 15.6701 6.46988 15.3301 6.87988 15.3301C7.28988 15.3301 7.62988 15.6701 7.62988 16.0801V18.1501C7.62988 18.5701 7.28988 18.9001 6.87988 18.9001Z'
                    fill='#020817'
                  />
                  <path
                    d='M12 18.9C11.59 18.9 11.25 18.56 11.25 18.15V14C11.25 13.59 11.59 13.25 12 13.25C12.41 13.25 12.75 13.59 12.75 14V18.15C12.75 18.57 12.41 18.9 12 18.9Z'
                    fill='#020817'
                  />
                  <path
                    d='M17.1201 18.8997C16.7101 18.8997 16.3701 18.5597 16.3701 18.1497V11.9297C16.3701 11.5197 16.7101 11.1797 17.1201 11.1797C17.5301 11.1797 17.8701 11.5197 17.8701 11.9297V18.1497C17.8701 18.5697 17.5401 18.8997 17.1201 18.8997Z'
                    fill='#020817'
                  />
                  <path
                    d='M6.87958 13.1804C6.53958 13.1804 6.23958 12.9504 6.14958 12.6104C6.04958 12.2104 6.28958 11.8004 6.69958 11.7004C10.3796 10.7804 13.6196 8.77041 16.0896 5.90041L16.5496 5.36041C16.8196 5.05041 17.2896 5.01041 17.6096 5.28041C17.9196 5.55041 17.9596 6.02041 17.6896 6.34041L17.2296 6.88041C14.5596 10.0004 11.0396 12.1704 7.05958 13.1604C6.99958 13.1804 6.93958 13.1804 6.87958 13.1804Z'
                    fill='#020817'
                  />
                  <path
                    d='M17.1204 9.51961C16.7104 9.51961 16.3704 9.17961 16.3704 8.76961V6.59961H14.1904C13.7804 6.59961 13.4404 6.25961 13.4404 5.84961C13.4404 5.43961 13.7804 5.09961 14.1904 5.09961H17.1204C17.5304 5.09961 17.8704 5.43961 17.8704 5.84961V8.77961C17.8704 9.18961 17.5404 9.51961 17.1204 9.51961Z'
                    fill='#020817'
                  />
                  <path
                    d='M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z'
                    fill='#020817'
                  />
                </svg>
              </div>
              <p className='text-muted-foreground text-sm'>
                <strong className='text-black dark:text-white font-bold'>
                  Showcase My Performance
                </strong>{' '}
                Demonstrate your site{"'"}s superior accessibility and usability
                achievements.
              </p>
            </li>
            <li className='flex gap-3 items-center'>
              <div>
                <svg
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M10.7896 15.1703C10.5896 15.1703 10.3996 15.0903 10.2596 14.9503L7.83957 12.5303C7.54957 12.2403 7.54957 11.7603 7.83957 11.4703C8.12957 11.1803 8.60957 11.1803 8.89957 11.4703L10.7896 13.3603L15.0896 9.06027C15.3796 8.77027 15.8596 8.77027 16.1496 9.06027C16.4396 9.35027 16.4396 9.83027 16.1496 10.1203L11.3196 14.9503C11.1796 15.0903 10.9896 15.1703 10.7896 15.1703Z'
                    fill='#020817'
                  />
                  <path
                    d='M11.9999 22.75C11.3699 22.75 10.7399 22.54 10.2499 22.12L8.66988 20.76C8.50988 20.62 8.10988 20.48 7.89988 20.48H6.17988C4.69988 20.48 3.49988 19.28 3.49988 17.8V16.09C3.49988 15.88 3.35988 15.49 3.21988 15.33L1.86988 13.74C1.04988 12.77 1.04988 11.24 1.86988 10.27L3.21988 8.68C3.35988 8.52 3.49988 8.13 3.49988 7.92V6.2C3.49988 4.72 4.69988 3.52 6.17988 3.52H7.90988C8.11988 3.52 8.51988 3.37 8.67988 3.24L10.2599 1.88C11.2399 1.04 12.7699 1.04 13.7499 1.88L15.3299 3.24C15.4899 3.38 15.8899 3.52 16.0999 3.52H17.7999C19.2799 3.52 20.4799 4.72 20.4799 6.2V7.9C20.4799 8.11 20.6299 8.51 20.7699 8.67L22.1299 10.25C22.9699 11.23 22.9699 12.76 22.1299 13.74L20.7699 15.32C20.6299 15.48 20.4799 15.88 20.4799 16.09V17.79C20.4799 19.27 19.2799 20.47 17.7999 20.47H16.0999C15.8899 20.47 15.4899 20.62 15.3299 20.75L13.7499 22.11C13.2599 22.54 12.6299 22.75 11.9999 22.75ZM6.17988 5.02C5.52988 5.02 4.99988 5.55 4.99988 6.2V7.91C4.99988 8.48 4.72988 9.21 4.35988 9.64L3.00988 11.23C2.65988 11.64 2.65988 12.35 3.00988 12.76L4.35988 14.35C4.72988 14.79 4.99988 15.51 4.99988 16.08V17.79C4.99988 18.44 5.52988 18.97 6.17988 18.97H7.90988C8.48988 18.97 9.21988 19.24 9.65988 19.62L11.2399 20.98C11.6499 21.33 12.3699 21.33 12.7799 20.98L14.3599 19.62C14.7999 19.25 15.5299 18.97 16.1099 18.97H17.8099C18.4599 18.97 18.9899 18.44 18.9899 17.79V16.09C18.9899 15.51 19.2599 14.78 19.6399 14.34L20.9999 12.76C21.3499 12.35 21.3499 11.63 20.9999 11.22L19.6399 9.64C19.2599 9.2 18.9899 8.47 18.9899 7.89V6.2C18.9899 5.55 18.4599 5.02 17.8099 5.02H16.1099C15.5299 5.02 14.7999 4.75 14.3599 4.37L12.7799 3.01C12.3699 2.66 11.6499 2.66 11.2399 3.01L9.65988 4.38C9.21988 4.75 8.47988 5.02 7.90988 5.02H6.17988Z'
                    fill='#020817'
                  />
                </svg>
              </div>
              <p className='text-muted-foreground text-sm'>
                <strong className='text-black dark:text-white font-bold'>
                  Build Credibility
                </strong>{' '}
                Demonstrate your dedication to inclusivity and gain trust from a
                broader.
              </p>
            </li>
          </ul>

          <div className='py-5 flex gap-3 items-center'>
            <Switch
              onCheckedChange={onChangeEvent}
              defaultChecked={website?.profileVisible}
            />
            Publish to Leaderboard
          </div>
        </div>
        <div className='flex-1'>
          <svg
            width='387'
            height='370'
            viewBox='0 0 387 370'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M354.347 207.021C354.073 120.049 283.754 49.7645 197.268 50.0399C110.783 50.3152 40.892 121.03 41.1658 208.002C41.3206 257.612 64.2723 301.786 100.021 330.542L296.253 329.931C331.812 300.96 354.501 256.642 354.347 207.033V207.021Z'
              fill='#F0F0F0'
            />
            <path
              d='M214.552 211.211H181.387V329.848H214.552V211.211Z'
              fill='white'
            />
            <mask
              id='mask0_836_142219'
              maskUnits='userSpaceOnUse'
              x='95'
              y='280'
              width='210'
              height='51'
            >
              <path
                d='M304.729 280.202H95.1279V330.889H304.729V280.202Z'
                fill='white'
              />
            </mask>
            <g mask='url(#mask0_836_142219)'>
              <path
                d='M282.413 368.022C295.87 362.46 302.297 346.981 296.766 333.447C291.236 319.914 275.843 313.452 262.386 319.013C248.928 324.575 242.502 340.054 248.032 353.587C253.562 367.121 268.955 373.583 282.413 368.022Z'
                fill='white'
              />
              <path
                d='M223.292 357.143C243.397 357.143 259.696 340.753 259.696 320.534C259.696 300.316 243.397 283.926 223.292 283.926C203.187 283.926 186.889 300.316 186.889 320.534C186.889 340.753 203.187 357.143 223.292 357.143Z'
                fill='white'
              />
              <path
                d='M147.123 357.319C154.716 344.838 150.811 328.53 138.4 320.894C125.989 313.257 109.772 317.185 102.179 329.665C94.5854 342.146 98.4907 358.454 110.902 366.09C123.313 373.727 139.529 369.799 147.123 357.319Z'
                fill='white'
              />
              <path
                d='M173.756 357.143C193.861 357.143 210.16 340.753 210.16 320.534C210.16 300.316 193.861 283.926 173.756 283.926C153.651 283.926 137.353 300.316 137.353 320.534C137.353 340.753 153.651 357.143 173.756 357.143Z'
                fill='white'
              />
            </g>
            <path
              d='M294.573 105.886C292.454 105.886 290.525 106.748 289.073 108.149C286.633 101.265 280.359 96.3447 272.966 96.3447C263.467 96.3447 255.765 104.449 255.765 114.457H302.704C302.704 109.729 299.061 105.886 294.561 105.886H294.573Z'
              fill='white'
            />
            <path
              d='M117.496 140.292C116.389 140.292 115.365 140.639 114.496 141.237C112.758 137.275 108.972 134.521 104.556 134.521C98.5081 134.521 93.6035 139.681 93.6035 146.062H122.972C122.972 142.878 120.519 140.292 117.496 140.292Z'
              fill='white'
            />
            <path
              d='M0 330.877L387 329.848'
              stroke='#EAECF0'
              strokeWidth='0.94'
              strokeMiterlimit='10'
            />
            <path
              d='M168.198 133.804L150.472 155.4C145.187 161.661 142.282 169.598 142.246 177.811L138.901 260.533L172.769 193.984L168.198 133.804Z'
              fill='#1F55C8'
            />
            <g opacity='0.54'>
              <path
                d='M168.199 133.804L163.056 139.909L155.842 227.264L171.687 196.139L168.199 133.804Z'
                fill='#599DEF'
              />
            </g>
            <path
              d='M226.243 133.804L243.969 155.4C249.254 161.661 252.159 169.598 252.195 177.811L255.54 260.533L221.672 193.984L226.243 133.804Z'
              fill='#1F55C8'
            />
            <g opacity='0.54'>
              <path
                d='M226.243 133.804L231.386 139.909L238.6 227.264L222.755 196.139L226.243 133.804Z'
                fill='#599DEF'
              />
            </g>
            <path
              d='M181.233 219.615L176.114 234.735C176.114 234.735 196.376 244.959 219.303 234.603L214.089 219.52L181.233 219.627V219.615Z'
              fill='#020817'
            />
            <path
              d='M198.34 15.4184C197.995 15.1551 197.495 15.2389 197.221 15.586C194.412 19.1296 179.341 40.2232 172.388 92.8854C162.579 167.288 165.127 220.153 165.127 220.153C165.127 220.153 197.578 231.37 229.97 219.95C229.97 219.95 228.97 137.79 222.363 90.4671C217.113 52.853 204.078 19.7641 198.34 15.4304V15.4184Z'
              fill='#6CA7FF'
            />
            <mask
              id='mask1_836_142219'
              maskUnits='userSpaceOnUse'
              x='164'
              y='15'
              width='66'
              height='211'
            >
              <path
                d='M198.34 15.4184C197.995 15.1551 197.495 15.2389 197.221 15.586C194.412 19.1296 179.341 40.2232 172.388 92.8854C162.579 167.288 165.127 220.153 165.127 220.153C165.127 220.153 197.578 231.37 229.97 219.95C229.97 219.95 228.97 137.79 222.363 90.4671C217.113 52.853 204.078 19.7641 198.34 15.4304V15.4184Z'
                fill='white'
              />
            </mask>
            <g mask='url(#mask1_836_142219)'>
              <path
                d='M174.77 45.0004C174.77 45.0004 196.852 65.3398 221.792 44.8567L199.019 6.29688L174.758 45.0004H174.77Z'
                fill='#58CEEE'
              />
              <g opacity='0.24'>
                <path
                  d='M197.744 12.582L198.768 235.777L236.1 225.206C236.1 225.206 242.898 40.4275 197.732 12.582H197.744Z'
                  fill='#B7B7B7'
                />
              </g>
            </g>
            <path
              d='M213.297 110.448C216.843 101.564 212.556 91.4704 203.721 87.9042C194.886 84.338 184.849 88.6494 181.303 97.534C177.757 106.419 182.044 116.512 190.879 120.078C199.714 123.644 209.751 119.333 213.297 110.448Z'
              fill='#1F55C8'
            />
            <path
              d='M209.483 103.959C209.507 110.723 204.078 116.217 197.352 116.229C190.626 116.253 185.162 110.794 185.15 104.031C185.139 97.2667 190.555 91.7718 197.281 91.7598C204.007 91.7359 209.471 97.1949 209.483 103.959Z'
              fill='white'
            />
            <path
              d='M197.733 130.44C197.733 130.44 195.638 137.012 195.054 166.234C194.471 195.456 196.757 266.65 196.757 266.65H200.376L200.28 166.138C200.28 166.138 199.935 141.92 197.733 130.428V130.44Z'
              fill='#1F55C8'
            />
            <path
              d='M87.3772 325.981L86.0439 313.423L78.3418 313.076L79.6989 326.879L85.9963 326.831L87.3772 325.981Z'
              fill='#FFBE9D'
            />
            <path
              d='M78.6869 323.072C78.6869 323.072 85.3652 325.861 88.4247 321.276L99.4958 327.861C100.436 328.423 100.043 329.872 98.9482 329.884L76.7227 330.039L78.6869 323.072Z'
              fill='#020817'
            />
            <path
              d='M70.2227 235.61L76.1153 317.937L88.234 317.853C88.234 317.853 87.7221 266.771 82.9008 234.855L70.2346 235.61H70.2227Z'
              fill='#6CA7FF'
            />
            <path
              d='M69.1266 326.113L69.3051 313.543L61.6149 313.196L61.4482 327.011L67.7576 326.963L69.1266 326.113Z'
              fill='#FFBE9D'
            />
            <path
              d='M60.4367 323.204C60.4367 323.204 67.1151 325.993 70.1745 321.408L84.6026 329.992L58.4844 330.183L60.4486 323.216L60.4367 323.204Z'
              fill='#020817'
            />
            <path
              d='M81.9725 236.412L77.0084 236.268L77.0322 235.885L57.9018 243.283C56.0447 273.487 58.9018 318.056 58.9018 318.056L72.7228 317.961L76.4846 246.276L81.9844 236.412H81.9725Z'
              fill='#6CA7FF'
            />
            <path
              d='M74.1504 290.581L74.698 254.236L79.1264 250.633'
              stroke='#4D97EF'
              strokeWidth='0.72'
              strokeMiterlimit='10'
            />
            <path
              d='M75.3184 203.491C75.3184 203.491 73.7828 207.059 81.3897 225.052C81.3897 225.052 85.1991 232.785 86.9728 233.001C89.8894 233.36 101.913 232.45 108.044 223.412L99.782 216.145L90.6394 221.544L85.1515 207.609C85.1515 207.609 81.9373 198.308 75.3184 203.491Z'
              fill='#3389F5'
            />
            <path
              d='M66.1633 198.487C63.0325 199.54 55.4256 203.036 55.4256 203.036C54.5565 203.431 54.0446 204.353 54.1756 205.299L57.9017 243.296C57.9017 243.296 69.318 248.348 83.8652 240.423L82.9604 205.562C83.0676 204.592 82.0319 203.156 81.3295 202.485L72.8774 197.996L66.1633 198.499V198.487Z'
              fill='#3389F5'
            />
            <path
              d='M82.1279 210.842L82.8303 225.172'
              stroke='#8ABBF9'
              strokeWidth='0.72'
              strokeMiterlimit='10'
            />
            <path
              d='M77.4732 228.248C77.4732 228.248 80.0922 234.09 68.7235 232.534L57.0215 233.432L57.95 229.852L65.4141 229.134L74.1876 229.074L77.4613 228.248H77.4732Z'
              fill='#FFBE9D'
            />
            <path
              d='M55.4263 203.036C55.4263 203.036 49.7717 204.916 43.5457 222.693C43.5457 222.693 41.2005 229.529 42.3791 230.834C44.3195 232.965 55.5096 236.161 66.0688 233.623L64.8427 228.104L54.4501 226.105L59.5809 212.59C59.5809 212.59 63.807 203.563 55.4263 203.024V203.036Z'
              fill='#3389F5'
            />
            <path
              d='M49.1299 227.303C51.3203 224.98 54.1416 226.022 54.1416 226.022L59.7248 213.464'
              stroke='#8ABBF9'
              strokeWidth='0.72'
              strokeMiterlimit='10'
            />
            <path
              d='M57.5811 234.82C57.5811 234.82 64.7356 234.593 66.0689 233.611'
              stroke='#8ABBF9'
              strokeWidth='0.72'
              strokeMiterlimit='10'
            />
            <path
              d='M61.5335 227.47L54.4385 226.105'
              stroke='#8ABBF9'
              strokeWidth='0.72'
              strokeMiterlimit='10'
            />
            <path
              d='M62.3438 229.337L83.0336 229.193V226.847L62.32 226.99C61.6771 226.99 61.1533 227.529 61.1652 228.176C61.1652 228.822 61.7009 229.349 62.3438 229.337Z'
              fill='#050001'
            />
            <path
              d='M80.3066 229.205L107.734 229.013C109.318 229.013 110.687 227.936 111.103 226.404L116.317 207.046C116.782 205.334 115.484 203.658 113.722 203.67L88.5326 203.85C87.4731 203.85 86.5564 204.58 86.3065 205.609L80.3066 229.217V229.205Z'
              fill='#050001'
            />
            <path
              d='M97.4376 216.479C97.4376 217.916 98.6161 219.077 100.045 219.065C101.473 219.065 102.628 217.88 102.616 216.443C102.604 215.007 101.437 213.846 100.009 213.858C98.5804 213.869 97.4257 215.043 97.4376 216.479Z'
              fill='white'
            />
            <path
              d='M61.5227 179.284C61.5227 179.284 61.356 173.514 69.9986 173.119C77.4865 172.784 78.4626 175.872 79.1293 177.991C79.7959 180.11 70.701 185.868 70.701 185.868L61.5227 179.284Z'
              fill='#171E21'
            />
            <path
              d='M66.1068 198.463L65.6426 186.91L73.0233 186.587L73.4876 197.744C73.5709 199.791 71.9876 200.725 69.952 200.809C67.9163 200.893 66.2021 200.51 66.1068 198.463Z'
              fill='#FFBE9D'
            />
            <path
              d='M65.6898 188.167C65.6898 188.167 64.6541 195.039 73.4753 196.691L73.2134 191.387L65.6898 188.167Z'
              fill='#E5A391'
            />
            <path
              d='M75.3555 174.759C75.3555 174.759 80.5815 174.699 79.2363 183.019L76.6411 179.464L75.3555 174.759Z'
              fill='#050001'
            />
            <path
              d='M75.4144 184.684C75.4858 186.108 76.6882 187.222 78.1167 187.15C79.5333 187.078 80.6404 185.869 80.569 184.432C80.4976 183.008 79.2952 181.894 77.8667 181.966C76.4501 182.038 75.343 183.247 75.4144 184.684Z'
              fill='#E5A391'
            />
            <path
              d='M72.0571 194.368C76.0093 194.177 79.1283 191.627 78.9378 187.652L78.5569 179.727C78.4735 177.991 76.9974 176.65 75.2832 176.734L67.1644 177.129C65.4382 177.213 64.1049 178.697 64.1883 180.433L64.5097 187.245C64.7002 191.22 68.7238 194.548 72.0571 194.392V194.368Z'
              fill='#FFBE9D'
            />
            <path
              d='M64.9621 185.006C64.9621 185.006 68.8786 182.373 67.1644 177.117L63.1288 177.309C63.1288 177.309 62.5336 176.411 61.7003 177.644C60.867 178.877 62.7479 184.611 62.7479 184.611L64.9621 185.006Z'
              fill='#050001'
            />
            <path
              d='M66.058 182.122L67.5103 178.135C67.5103 178.135 77.1052 179.332 79.4623 176.603C81.7241 173.981 79.879 170.066 77.9623 169.647C76.4624 169.324 74.8434 170.725 73.7006 171.347C72.5578 171.982 69.8197 171.395 68.9983 171.347C68.1769 171.299 64.6175 170.569 63.5461 174.34C63.5461 174.34 57.7606 176.495 63.7604 186.288C63.7604 186.288 66.1056 184.851 66.058 182.122Z'
              fill='#050001'
            />
            <path
              d='M61.142 185.569C61.1658 187.042 62.38 188.203 63.8443 188.179C65.3085 188.155 66.4632 186.934 66.4394 185.462C66.4156 183.989 65.2014 182.828 63.7371 182.852C62.2729 182.876 61.1182 184.097 61.142 185.569Z'
              fill='#FFBE9D'
            />
            <path
              d='M66.9748 181.547C66.9748 181.547 66.7129 186.851 67.8915 188.383C69.07 189.915 69.6771 188.335 73.8437 187.677C78.0102 187.018 79.0697 188.934 79.0697 188.934C79.0697 188.934 78.8792 194.273 73.4151 194.536C69.1653 194.74 64.8321 193.746 65.6535 186.911C66.4749 180.075 64.1416 178.423 64.1416 178.423L66.9868 181.559L66.9748 181.547Z'
              fill='#050001'
            />
            <path
              d='M67.1409 197.756L62.9268 199.731C62.9268 199.731 63.7839 202.952 70.3075 202.688C71.1408 202.652 71.9741 202.533 72.7717 202.245C73.9026 201.838 75.3192 201.048 75.8073 199.54L72.236 197.337L67.1409 197.744V197.756Z'
              fill='#FFBE9D'
            />
            <path
              opacity='0.5'
              d='M79.5317 31.7119H73.9129C72.4336 31.7119 71.2344 32.9179 71.2344 34.4055V39.7088C71.2344 41.1964 72.4336 42.4024 73.9129 42.4024H79.5317C81.011 42.4024 82.2102 41.1964 82.2102 39.7088V34.4055C82.2102 32.9179 81.011 31.7119 79.5317 31.7119Z'
              fill='#8ABBF9'
            />
            <path
              d='M57.2597 159.901L23.5703 159.949C22.2251 159.949 21.1299 158.86 21.1299 157.507C21.1299 156.154 22.2132 155.053 23.5584 155.053L57.2478 155.005C58.593 155.005 59.6882 156.094 59.6882 157.447C59.6882 158.8 58.6049 159.901 57.2597 159.901Z'
              fill='#8ABBF9'
            />
            <path
              d='M365.715 135.995L299.728 136.043C298.383 136.043 297.288 134.953 297.288 133.601C297.288 132.248 298.371 131.147 299.717 131.147L365.703 131.099C367.048 131.099 368.143 132.188 368.143 133.541C368.143 134.894 367.06 135.995 365.715 135.995Z'
              fill='#3389F5'
            />
            <path
              d='M379.488 205.985L382.607 205.981C383.428 205.98 384.096 206.649 384.097 207.475L384.101 210.42C384.102 211.246 383.437 211.917 382.615 211.919L379.496 211.923C378.675 211.924 378.007 211.255 378.006 210.429L378.002 207.484C378.001 206.658 378.667 205.986 379.488 205.985Z'
              fill='#3389F5'
            />
            <path
              d='M266.339 19.3549L263.232 19.3592C262.41 19.3604 261.745 20.0313 261.746 20.8578L261.75 23.7907C261.751 24.6172 262.419 25.2862 263.24 25.2851L266.347 25.2807C267.169 25.2796 267.835 24.6087 267.833 23.7822L267.829 20.8492C267.828 20.0228 267.161 19.3537 266.339 19.3549Z'
              fill='#8ABBF9'
            />
            <path
              opacity='0.5'
              d='M14.7812 246.231L7.81713 246.24C5.92365 246.243 4.39082 247.789 4.39346 249.693L4.40318 256.696C4.40583 258.6 5.94294 260.142 7.83643 260.139L14.8005 260.129C16.694 260.127 18.2268 258.581 18.2242 256.677L18.2144 249.673C18.2118 247.769 16.6747 246.228 14.7812 246.231Z'
              fill='#8ABBF9'
            />
            <path
              d='M32.7486 119.988L93.6753 119.988C95.7331 119.988 97.4014 118.311 97.4014 116.241V66.8951C97.4014 64.8257 95.7331 63.1481 93.6753 63.1481L32.7486 63.1481C30.6908 63.1481 29.0225 64.8257 29.0225 66.8951V116.241C29.0225 118.311 30.6908 119.988 32.7486 119.988Z'
              fill='#3389F5'
            />
            <path
              d='M76.7825 87.6302L74.5326 87.2351C74.2707 86.2534 73.8778 85.3077 73.3779 84.4218L74.6873 82.5423C75.1635 81.848 75.0921 80.9142 74.4969 80.3156L73.1517 78.9629C72.5445 78.3763 71.616 78.3044 70.9375 78.8072L69.0685 80.1241C68.1875 79.6093 67.2471 79.2262 66.2709 78.9629L65.89 76.6524C65.7471 75.8264 65.0448 75.2278 64.2234 75.2158H62.283C61.4377 75.2158 60.7235 75.8264 60.5806 76.6524L60.1878 78.903C59.2116 79.1664 58.2712 79.5614 57.3903 80.0642L55.5332 78.8072C54.8427 78.3284 53.9142 78.4002 53.319 78.9988L51.9738 80.3516C51.3785 80.9501 51.2952 81.8839 51.7833 82.5782L53.0928 84.4577C52.5809 85.3436 52.1999 86.2894 51.938 87.271L49.7 87.6661C48.8667 87.8097 48.2715 88.54 48.2715 89.378V91.2934C48.2715 92.1434 48.8786 92.8617 49.7 93.0053L51.938 93.4004C52.1999 94.382 52.5928 95.3278 53.0928 96.2137L51.7833 98.0932C51.3071 98.7875 51.3785 99.7213 51.9738 100.32L53.319 101.673C53.9142 102.271 54.8427 102.355 55.5332 101.864L57.4022 100.547C58.2831 101.062 59.2235 101.445 60.1997 101.709L60.5925 103.959C60.7354 104.797 61.4616 105.396 62.2949 105.396H64.2234C65.0686 105.396 65.7829 104.785 65.9257 103.959L66.3185 101.709C67.2947 101.445 68.2352 101.05 69.1161 100.547L70.9375 101.84C71.6279 102.319 72.5565 102.247 73.1517 101.649L74.4969 100.296C75.0921 99.6973 75.1754 98.7636 74.6873 98.0692L73.3779 96.1777C73.8897 95.2918 74.2707 94.3461 74.5326 93.3645L76.7825 92.9694C77.6158 92.8257 78.211 92.0955 78.211 91.2575V89.3421C78.211 88.5041 77.6039 87.7738 76.7825 87.6302ZM63.2115 95.4714C60.3663 95.4714 58.0688 93.1609 58.0688 90.2998C58.0688 87.4386 60.3663 85.1281 63.2115 85.1281C66.0567 85.1281 68.3542 87.4386 68.3542 90.2998V90.3237C68.3423 93.1729 66.0447 95.4714 63.2115 95.4714Z'
              fill='white'
            />
            <path
              d='M159.161 18.3411C159.214 8.26508 151.15 0.0542106 141.15 0.00155631C131.15 -0.051098 123.001 8.0744 122.949 18.1504C122.896 28.2263 130.96 36.4372 140.96 36.4899C150.96 36.5425 159.109 28.417 159.161 18.3411Z'
              fill='#8ABBF9'
            />
            <path
              d='M144.889 11.0137C141.365 10.9778 141.079 13.1327 141.079 13.1327C141.079 13.1327 140.841 10.9658 137.317 10.9299C133.794 10.894 132.508 14.9403 133.401 17.3825C134.079 19.262 138.186 23.3921 140.032 25.1998C140.544 25.6907 141.341 25.7026 141.853 25.2238C143.746 23.464 147.936 19.4296 148.662 17.5621C149.603 15.1439 148.412 11.0616 144.889 11.0257V11.0137Z'
              fill='white'
            />
            <path
              d='M303.727 24.541H357.213C360.654 24.541 363.439 27.3423 363.439 30.8021V72.1632C363.439 75.623 360.654 78.4243 357.213 78.4243H305.477C301.072 78.4243 297.501 74.8329 297.501 70.4034V30.8021C297.501 27.3423 300.287 24.541 303.727 24.541Z'
              fill='#F9AE40'
            />
            <path
              d='M321.404 51.835H314.226V64.2134H321.404V51.835Z'
              fill='white'
            />
            <path
              d='M332.403 36.3203H325.225V64.2137H332.403V36.3203Z'
              fill='white'
            />
            <path
              d='M343.39 42.1025H336.212V64.2137H343.39V42.1025Z'
              fill='white'
            />
            <path
              d='M306.833 64.2139H353.248'
              stroke='white'
              strokeWidth='0.88'
              strokeMiterlimit='10'
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
