import React from 'react'
import { getSelectStyle } from './select-style'
import { useTranslation } from '@app/lib/hooks/useTranslation'

// accessibility standards to test
export enum Standard {
  'WCAG2A',
  'WCAG2AA',
  'WCAG2AAA',
  'Section508',
}

// todo: remove type and reverse enum map instead
export type AccessibilityStandardKeys = keyof typeof Standard | string

const standards = Object.values(Standard).filter(
  (value) => typeof value === 'string'
)

export interface InputProps {
  onStandardChange?(
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ): void
  standard?: AccessibilityStandardKeys
  spacing?: boolean
  className?: string
  disabled?: boolean
  label?: string
  borderLess?: boolean
}

export const WCAGSelectInput = ({
  onStandardChange,
  standard,
  spacing,
  className,
  disabled,
  label,
  borderLess,
}: InputProps) => {
  const id = label ?? 'ext-select-outlined'
  const { t } = useTranslation('dashboard')
  const l = t('accessibility-standard')

  return (
    <div>
      <label htmlFor={id} className='sr-only'>
        {l}
      </label>
      <select
        id={id}
        value={typeof standard === 'number' ? Standard[standard] : standard}
        onChange={onStandardChange}
        className={getSelectStyle({
          disabled,
          spacing,
          className,
          borderLess,
        })}
        disabled={disabled}
      >
        <optgroup label={l}>
          {standards.map((value: any) => (
            <option value={value} key={value} className={'text-sm uppercase'}>
              {value && String(value)}
            </option>
          ))}
        </optgroup>
      </select>
    </div>
  )
}
