import { Input } from '@app/@/components/ui/input'
import { Label } from '@app/@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@app/@/components/ui/select'
import { Button } from '@app/@/components/ui/button'
import { useWebsiteContext } from '@app/components/providers/website'
import { useTranslation } from '@app/lib/hooks/useTranslation'

export const StepTwo = ({
  active,
  defaultValue,
  readOnly,
}: {
  active: boolean
  defaultValue?: string
  readOnly?: boolean
}) => {
  const { addWebsiteForm } = useWebsiteContext()

  const { t } = useTranslation('leaderboards-categories')

  const items = t('tags', undefined, { returnObjects: true })
  const sectorOptions = Object.entries(items || [])

  return (
    <div className={`px-5 h-full${!active ? ' hidden' : ''}`}>
      <div>
        <div className='flex flex-1 flex-col md:flex-row gap-3 items-center h-full w-full md:gap-6'>
          <div>
            <Label className='font-bold'>Enter your site URL</Label>
            <Input
              placeholder='https://www.your-site.com/'
              onChange={addWebsiteForm.onChangeText}
              defaultValue={defaultValue}
              readOnly={readOnly}
            />
          </div>
          <div>
            <Label className='font-bold'>Choose Sector</Label>
            <Select>
              <SelectTrigger className='w-[180px]'>
                <SelectValue placeholder='General' />
              </SelectTrigger>
              <SelectContent>
                {Array.isArray(sectorOptions)
                  ? sectorOptions?.map((item, i) => (
                      <SelectItem value={item[0]} key={`tlist-${i}`}>
                        {item[1]}
                      </SelectItem>
                    ))
                  : null}
              </SelectContent>
            </Select>
          </div>
        </div>
        <div>
          <div className='flex gap-6 items-center py-12'>
            <div className='flex items-center flex-col place-content-center border-2 rounded-md border-dashed py-9 px-4'>
              <svg
                width='42'
                height='36'
                viewBox='0 0 42 36'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M12.2503 31.4719C9.85984 31.3146 7.58853 30.5139 5.76065 29.1842C3.93277 27.8545 2.64177 26.0637 2.072 24.0676C1.50223 22.0716 1.68282 19.9722 2.58799 18.0693C3.49315 16.1665 5.0766 14.5574 7.11225 13.4719C7.5454 10.577 9.1944 7.91663 11.7507 5.98861C14.3069 4.0606 17.5952 2.99707 21.0003 2.99707C24.4053 2.99707 27.6936 4.0606 30.2498 5.98861C32.8061 7.91663 34.4551 10.577 34.8883 13.4719C36.9239 14.5574 38.5074 16.1665 39.4125 18.0693C40.3177 19.9722 40.4983 22.0716 39.9285 24.0676C39.3587 26.0637 38.0677 27.8545 36.2399 29.1842C34.412 30.5139 32.1407 31.3146 29.7503 31.4719V31.5004H12.2503V31.4719ZM22.7503 19.5004H28.0003L21.0003 12.0004L14.0003 19.5004H19.2503V25.5004H22.7503V19.5004Z'
                  fill='#8D9BA7'
                />
              </svg>
              <p className='text-muted-foreground text-xs'>
                Browse files to upload
              </p>
            </div>

            <div className='space-y-1'>
              <div>
                <h4 className='text-base font-semibold'>Site Image</h4>
                <div className='text-sm text-muted-foreground w-1/2'>
                  Recommended resolution 64x64px. Image size should not exceed
                  2MB.
                </div>
              </div>
              <Button
                className='flex items-center place-content-center gap-2 border border-blue-500 text-blue-500'
                variant={'ghost'}
              >
                <svg
                  width='24'
                  height='25'
                  viewBox='0 0 24 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M9.00043 18.2505C8.59043 18.2505 8.25043 17.9105 8.25043 17.5005V13.3105L7.53043 14.0305C7.24043 14.3205 6.76043 14.3205 6.47043 14.0305C6.18043 13.7405 6.18043 13.2605 6.47043 12.9705L8.47043 10.9705C8.68043 10.7605 9.01043 10.6905 9.29043 10.8105C9.57043 10.9205 9.75043 11.2005 9.75043 11.5005V17.5005C9.75043 17.9105 9.41043 18.2505 9.00043 18.2505Z'
                    fill='#006CF2'
                  />
                  <path
                    d='M11.0004 14.2504C10.8104 14.2504 10.6204 14.1804 10.4704 14.0304L8.47043 12.0304C8.18043 11.7404 8.18043 11.2604 8.47043 10.9704C8.76043 10.6804 9.24043 10.6804 9.53043 10.9704L11.5304 12.9704C11.8204 13.2604 11.8204 13.7404 11.5304 14.0304C11.3804 14.1804 11.1904 14.2504 11.0004 14.2504Z'
                    fill='#006CF2'
                  />
                  <path
                    d='M15 23.25H9C3.57 23.25 1.25 20.93 1.25 15.5V9.5C1.25 4.07 3.57 1.75 9 1.75H14C14.41 1.75 14.75 2.09 14.75 2.5C14.75 2.91 14.41 3.25 14 3.25H9C4.39 3.25 2.75 4.89 2.75 9.5V15.5C2.75 20.11 4.39 21.75 9 21.75H15C19.61 21.75 21.25 20.11 21.25 15.5V10.5C21.25 10.09 21.59 9.75 22 9.75C22.41 9.75 22.75 10.09 22.75 10.5V15.5C22.75 20.93 20.43 23.25 15 23.25Z'
                    fill='#006CF2'
                  />
                  <path
                    d='M22 11.2505H18C14.58 11.2505 13.25 9.92048 13.25 6.50048V2.50048C13.25 2.20048 13.43 1.92048 13.71 1.81048C13.99 1.69048 14.31 1.76048 14.53 1.97048L22.53 9.97048C22.74 10.1805 22.81 10.5105 22.69 10.7905C22.57 11.0705 22.3 11.2505 22 11.2505ZM14.75 4.31048V6.50048C14.75 9.08048 15.42 9.75048 18 9.75048H20.19L14.75 4.31048Z'
                    fill='#006CF2'
                  />
                </svg>
                Upload
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
