import React from 'react'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { inputStyle } from '../cells/blocks/input-style'
import { InfoBlock } from '../cells'
import type { InputBoxProps } from '@app/types/dashboard'
import { Input } from '@app/@/components/ui/input'

interface RootSelectorProps extends InputBoxProps {
  url?: string
  activeSubscription?: boolean
  translation?: string
  tooltip?: boolean
  value?: string
  id?: string
}
// the agent input
export const RootSelectorInput = ({
  activeSubscription,
  onChange,
  value,
  translation,
  tooltip = true,
  url,
  id,
  dialog,
}: RootSelectorProps & { onChange?(x: any): any }) => {
  const { t } = useTranslation(translation || 'dashboard')

  const rootText = t('add-root-element')
  const uaLabel = `${url || id}-root-selector`

  return (
    <InfoBlock
      tipID={'root-id'}
      toolTip={tooltip ? t('dashboard-inputs:root-tip') : undefined}
      noPx={dialog}
      labelTip={<label htmlFor={uaLabel}>{rootText}</label>}
      noClass
    >
      <Input
        className={inputStyle(activeSubscription, dialog)}
        onChange={onChange}
        value={value}
        id={uaLabel}
        placeholder={rootText}
        type='text'
        name={'root_selector'}
      />
    </InfoBlock>
  )
}
