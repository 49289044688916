import Link from 'next/link'
import { Menu } from 'lucide-react'
import { Button, buttonVariants } from '@app/@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@app/@/components/ui/dropdown-menu'
import { Sheet, SheetContent, SheetTrigger } from '@app/@/components/ui/sheet'
// import { IssueFeed } from '@app/components/feed'
import { Logo } from '../general/navigation'
import { Separator } from '@app/@/components/ui/separator'
// import { DashboardWebsiteList } from '../general/dashboard-website-list'
import { DrawerLinkButton } from '../general/drawers/menu/drawer-link-button'
import { DashboardInnerText } from '../general/drawers/menu/items/dashboard-inner'
import { AllSitesInnerText } from '../general/drawers/menu/items/all-sites-inner'
import useTranslation from 'next-translate/useTranslation'
import { DirectScansText } from '../general/drawers/menu/items/direct-scans-inner'
import { LeaderboardsText } from '../general/drawers/menu/items/leaderboard-inner'
import { NotificationsText } from '../general/drawers/menu/items/notifications-inner'
import { SettingsText } from '../general/drawers/menu/items/settings-inner'
import { APIText } from '../general/drawers/menu/items/api-inner'
import { ThemeText } from '../general/drawers/menu/items/theme-inner'
import { DrawerThemeButton } from '../general/drawers/menu/drawer-theme-button'
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react'
// import { DashboardTopMenu } from './dashboard-top-menu'
import { useWebsiteContext } from '../providers/website'
import { HomeBtn } from '../general/navigation/home-btn'
import { useUserData } from '@app/data'
import { Skeleton } from '@app/@/components/ui/skeleton'
import { StepOne } from './steps/body/one'
import { StepBar } from './steps/step-bar'
import { AddWebsiteStep } from '@app/data/external/website/websites'
import { StepOneMenuItem } from './steps/menu/step-one'
import { StepTwoMenuItem } from './steps/menu/step-two'
import { StepThreeMenuItem } from './steps/menu/step-three'
import { StepFourMenuItem } from './steps/menu/step-four'
import { StepFiveMenuItem } from './steps/menu/step-five'
import { StepTwo } from './steps/body/two'
import { StepFour } from './steps/body/fourth'
import { StepFive } from './steps/body/fifth'
import { StepThree } from './steps/body/third'
import { Progress } from '@app/@/components/ui/progress'
import { LogoutButton } from '../general/auth-menu'

// Active routes to display on the dashboard.
export enum ActiveRoute {
  Dashboard,
  SiteAnalytics,
  DirectScans,
  Leaderboards,
  Notifications,
  Settings,
  API,
  Theme,
  Profile,
}

export const DashboardBase: FC<
  PropsWithChildren<{
    emptyList?: boolean
    defaultOpenMenu?: boolean
    headerChildren?: ReactNode | JSX.Element
    disableHeader?: boolean
    activeRoute?: ActiveRoute
    mock?: boolean
  }>
> = ({
  emptyList,
  defaultOpenMenu,
  children,
  headerChildren,
  disableHeader,
  activeRoute,
  mock,
}) => {
  const { t } = useTranslation('common')
  const [openDrawer, setDrawerOpen] = useState<boolean>(!!defaultOpenMenu)
  const { feedOpen, setIssueFeedContent, addWebsiteStep, setAddWebsiteStep } =
    useWebsiteContext()
  const { data: userData, loading } = useUserData()

  const onOpenDrawer = () => setDrawerOpen((v) => !v)

  const cancelFeedEvent = () => {
    setIssueFeedContent(false)
  }

  useEffect(() => {
    if (defaultOpenMenu) {
      setIssueFeedContent(false)
    }
    // todo: remove for callback
    if (feedOpen) {
      setDrawerOpen(true)
    }
  }, [feedOpen, defaultOpenMenu, setIssueFeedContent])

  const baseStyle = `${feedOpen ? `md:grid-cols-[80px_1fr] overflow-hidden` : `md:grid-cols-[80px_1fr] overflow-hidden`}`

  const getStepTitle = () => {
    let step = ''

    switch (addWebsiteStep) {
      case AddWebsiteStep.First: {
        step = 'Choose how many sites you want to add?'
        break
      }
      case AddWebsiteStep.Second: {
        step = 'General Information'
        break
      }
      case AddWebsiteStep.Third: {
        step = 'Configuration of Site'
        break
      }
      case AddWebsiteStep.Fourth: {
        step = 'Scan Frequency'
        break
      }
      case AddWebsiteStep.Fifth: {
        step = 'Publish You’re Site'
        break
      }
      default: {
        break
      }
    }

    return step
  }

  return (
    <div
      className={`transition-all relative grid min-h-screen w-full ${openDrawer ? baseStyle : `md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]`}`}
    >
      <div className='hidden border-r bg-muted/40 md:block'>
        <div className='flex flex-col h-full max-h-screen gap-2'>
          <div className='flex h-14 items-center px-4 lg:h-[6.5rem] lg:px-6'>
            <HomeBtn openDrawer={openDrawer} />
            <Button
              variant='outline'
              size='icon'
              onClick={onOpenDrawer}
              className={`h-8 w-8 p-2 rounded-full${openDrawer ? ` ml-3 z-10 lg:ml-2` : ' ml-auto'}`}
            >
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.9993 20.6695C14.8093 20.6695 14.6193 20.5995 14.4693 20.4495L7.9493 13.9295C6.8893 12.8695 6.8893 11.1295 7.9493 10.0695L14.4693 3.54953C14.7593 3.25953 15.2393 3.25953 15.5293 3.54953C15.8193 3.83953 15.8193 4.31953 15.5293 4.60953L9.0093 11.1295C8.5293 11.6095 8.5293 12.3895 9.0093 12.8695L15.5293 19.3895C15.8193 19.6795 15.8193 20.1595 15.5293 20.4495C15.3793 20.5895 15.1893 20.6695 14.9993 20.6695Z'
                  fill='#006CF2'
                />
              </svg>
              <span className='sr-only'>Toggle Panel</span>
            </Button>
          </div>
          <div className='flex-1'>
            <nav className='grid items-start px-2 text-sm font-medium lg:px-4'>
              <div className='py-2'>
                <p className={`text-sm${openDrawer ? ' hidden' : ''}`}>
                  {t('menu:main-menu')}
                </p>
              </div>
              <DrawerLinkButton
                active={activeRoute === ActiveRoute.Dashboard}
                href={'/dashboard'}
                mock={mock}
              >
                <DashboardInnerText t={t} textHidden={openDrawer} />
              </DrawerLinkButton>
              <DrawerLinkButton
                href={'/dashboard/all-sites-analytics'}
                active={activeRoute === ActiveRoute.SiteAnalytics}
                mock={mock}
              >
                <AllSitesInnerText t={t} textHidden={openDrawer} />
              </DrawerLinkButton>
              <DrawerLinkButton
                href={'/dashboard/direct-scans'}
                active={activeRoute === ActiveRoute.DirectScans}
                mock={mock}
              >
                <DirectScansText t={t} textHidden={openDrawer} />
              </DrawerLinkButton>
              <DrawerLinkButton
                href={'/leaderboards'}
                active={activeRoute === ActiveRoute.Leaderboards}
                mock={mock}
              >
                <LeaderboardsText t={t} textHidden={openDrawer} />
              </DrawerLinkButton>
              <div className='py-10'>
                <p className={`text-sm${openDrawer ? ' hidden' : ''}`}>
                  {t('dashboard:preferences')}
                </p>
                <div className='py-6'>
                  <DrawerLinkButton
                    href={'/settings#notifications'}
                    active={activeRoute === ActiveRoute.Notifications}
                  >
                    <NotificationsText t={t} textHidden={openDrawer} />
                  </DrawerLinkButton>
                  <DrawerLinkButton
                    href={'/settings'}
                    active={activeRoute === ActiveRoute.Settings}
                    mock={mock}
                  >
                    <SettingsText t={t} textHidden={openDrawer} />
                  </DrawerLinkButton>
                  <DrawerLinkButton
                    href={'/api-info'}
                    active={activeRoute === ActiveRoute.API}
                    mock={mock}
                  >
                    <APIText t={t} textHidden={openDrawer} />
                  </DrawerLinkButton>
                  <DrawerThemeButton
                    className='place-content-between'
                    openDrawer={openDrawer}
                    mock={mock}
                  >
                    <ThemeText t={t} textHidden={openDrawer} />
                  </DrawerThemeButton>
                </div>
              </div>
            </nav>
          </div>
          <div className='mt-auto p-4'>
            <div className='py-3'>
              <Separator />
            </div>
            {!userData?.user?.email ? (
              <a
                className={`flex place-content-between w-full ${buttonVariants()}`}
                href={'/login'}
              >
                {t('login')}
              </a>
            ) : (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant='ghost'
                    className='flex place-content-between w-full'
                  >
                    <div className='flex gap-3 flex-1 items-center'>
                      {/* <div className='w-7 h-7 rounded-lg bg-blue-600 dark:bg-blue-700 flex place-content-center p-1 items-center'>
                      HA
                    </div> */}
                      <p className={openDrawer ? 'sr-only' : 'truncate'}>
                        {userData?.user?.email && loading ? (
                          <Skeleton className='w-4 h-5' />
                        ) : (
                          userData?.user?.email || t('login')
                        )}
                      </p>
                    </div>

                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      className='fill-black dark:fill-white'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path d='M14.75 19C14.75 20.52 13.52 21.75 12 21.75C10.48 21.75 9.25 20.52 9.25 19C9.25 17.48 10.48 16.25 12 16.25C13.52 16.25 14.75 17.48 14.75 19ZM10.75 19C10.75 19.69 11.31 20.25 12 20.25C12.69 20.25 13.25 19.69 13.25 19C13.25 18.31 12.69 17.75 12 17.75C11.31 17.75 10.75 18.31 10.75 19Z' />
                      <path d='M14.75 5C14.75 6.52 13.52 7.75 12 7.75C10.48 7.75 9.25 6.52 9.25 5C9.25 3.48 10.48 2.25 12 2.25C13.52 2.25 14.75 3.48 14.75 5ZM10.75 5C10.75 5.69 11.31 6.25 12 6.25C12.69 6.25 13.25 5.69 13.25 5C13.25 4.31 12.69 3.75 12 3.75C11.31 3.75 10.75 4.31 10.75 5Z' />
                      <path d='M14.75 12C14.75 13.52 13.52 14.75 12 14.75C10.48 14.75 9.25 13.52 9.25 12C9.25 10.48 10.48 9.25 12 9.25C13.52 9.25 14.75 10.48 14.75 12ZM10.75 12C10.75 12.69 11.31 13.25 12 13.25C12.69 13.25 13.25 12.69 13.25 12C13.25 11.31 12.69 10.75 12 10.75C11.31 10.75 10.75 11.31 10.75 12Z' />
                    </svg>

                    <span className='sr-only'>Toggle user menu</span>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align='end'
                  className={mock ? 'hidden' : ''}
                >
                  <DropdownMenuLabel>{t('menu:my')}</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>
                    <Link href={'/profile'} className='w-full'>
                      {t('menu:profile')}
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Link href={'/payments'} className='w-full'>
                      {t('menu:payments')}
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Link href={'/settings'} className='w-full'>
                      {t('menu:settings')}
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>
                    <LogoutButton
                      className='w-full text-left rtl:text-right px-0.5 py-0.5'
                      raw
                      logoutText={t('menu:logout')}
                    />
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>

      <div className='flex flex-col'>
        {disableHeader ? null : (
          <header
            className={`${feedOpen ? 'hidden' : 'flex'} h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[6.5rem] lg:px-6`}
          >
            <Sheet>
              <SheetTrigger asChild>
                <Button
                  variant='outline'
                  size='icon'
                  className='shrink-0 md:hidden'
                >
                  <Menu className='h-5 w-5' />
                  <span className='sr-only'>{t('menu:toggle-menu')}</span>
                </Button>
              </SheetTrigger>
              <SheetContent side='left' className='flex flex-col'>
                <nav className='grid gap-2 text-lg font-medium'>
                  <Link
                    href='#'
                    className='flex items-center gap-2 text-lg font-semibold'
                  >
                    <Logo className='h-6 w-6' />
                    <span className='sr-only'>Muneer</span>
                  </Link>

                  <div className='pb-2 pt-6'>
                    <p className='text-sm'>{t('menu:main-menu')}</p>
                  </div>

                  <DrawerLinkButton
                    active={activeRoute === ActiveRoute.Dashboard}
                    href={'/dashboard'}
                    className='mx-[-0.65rem]'
                    mock={mock}
                  >
                    <DashboardInnerText t={t} />
                  </DrawerLinkButton>

                  <DrawerLinkButton
                    href={'/dashboard/all-sites-analytics'}
                    active={activeRoute === ActiveRoute.SiteAnalytics}
                    className='mx-[-0.65rem]'
                    mock={mock}
                  >
                    <AllSitesInnerText t={t} />
                  </DrawerLinkButton>

                  <DrawerLinkButton
                    href={'/dashboard/direct-scans'}
                    active={activeRoute === ActiveRoute.DirectScans}
                    className='mx-[-0.65rem]'
                    mock={mock}
                  >
                    <DirectScansText t={t} />
                  </DrawerLinkButton>

                  <DrawerLinkButton
                    href={'/leaderboards'}
                    active={activeRoute === ActiveRoute.Leaderboards}
                    className='mx-[-0.65rem]'
                    mock={mock}
                  >
                    <LeaderboardsText t={t} />
                  </DrawerLinkButton>

                  <div className='py-3'>
                    <p className='text-sm'>{t('dashboard:preferences')}</p>
                    <div className='py-4'>
                      <DrawerLinkButton
                        href={'/dashboard/direct-scans'}
                        className='mx-[-0.65rem]'
                        active={activeRoute === ActiveRoute.Notifications}
                      >
                        <NotificationsText t={t} />
                      </DrawerLinkButton>
                      <DrawerLinkButton
                        href={'/dashboard/direct-scans'}
                        className='mx-[-0.65rem]'
                        active={activeRoute === ActiveRoute.Settings}
                        mock={mock}
                      >
                        <SettingsText t={t} />
                      </DrawerLinkButton>
                      <DrawerLinkButton
                        href={'/dashboard/direct-scans'}
                        className='mx-[-0.65rem]'
                        active={activeRoute === ActiveRoute.API}
                        mock={mock}
                      >
                        <APIText t={t} />
                      </DrawerLinkButton>

                      <DrawerThemeButton
                        className='place-content-between mx-[-0.65rem]'
                        active={activeRoute === ActiveRoute.Theme}
                        mock={mock}
                      >
                        <ThemeText t={t} />
                      </DrawerThemeButton>

                      <DrawerThemeButton
                        className='place-content-between mx-[-0.65rem]'
                        mock={mock}
                      >
                        <LogoutButton
                          className='w-full py-0.5'
                          raw
                          logoutText={t('menu:logout')}
                        />
                      </DrawerThemeButton>
                    </div>
                  </div>
                </nav>
                <div className='mt-auto'></div>
              </SheetContent>
            </Sheet>
            {headerChildren}
          </header>
        )}
        <main className='min-h-[100vh]'>
          <div
            className={`flex flex-1 h-full w-full ${emptyList ? ' place-content-center' : ''} max-w-[100vw]${feedOpen ? ` flex-col md:flex-row` : ''}`}
          >
            <div
              className={`${feedOpen ? 'z-20 px-4 flex flex-col border-r py-2 md:max-w-[18rem]' : 'hidden'}`}
            >
              <Button
                onClick={cancelFeedEvent}
                variant={'outline'}
                className='text-blue-500 md:hidden'
              >
                {t('forms:cancel')}
              </Button>

              <div className='py-4 md:py-20'>
                <h3 className='text-base md:text-lg lg:text-xl font-bold'>
                  {t('dashboard:add-new')}
                </h3>
              </div>

              <ul className='flex flex-1 md:flex-col gap-3 overflow-auto'>
                <button
                  type='button'
                  onClick={() => setAddWebsiteStep(AddWebsiteStep.First)}
                  className='text-left rtl:text-right'
                >
                  <StepOneMenuItem
                    active={addWebsiteStep === AddWebsiteStep.First}
                    completed={!!addWebsiteStep}
                  />
                </button>
                <button
                  type='button'
                  onClick={() => setAddWebsiteStep(AddWebsiteStep.Second)}
                  className='text-left rtl:text-right'
                >
                  <StepTwoMenuItem
                    active={addWebsiteStep === AddWebsiteStep.Second}
                    completed={addWebsiteStep > 1}
                  />
                </button>
                <button
                  type='button'
                  onClick={() => setAddWebsiteStep(AddWebsiteStep.Third)}
                  className='text-left rtl:text-right'
                >
                  <StepThreeMenuItem
                    active={addWebsiteStep === AddWebsiteStep.Third}
                    completed={addWebsiteStep > 2}
                  />
                </button>
                <button
                  type='button'
                  onClick={() => setAddWebsiteStep(AddWebsiteStep.Fourth)}
                  className='text-left rtl:text-right'
                >
                  <StepFourMenuItem
                    active={addWebsiteStep === AddWebsiteStep.Fourth}
                    completed={addWebsiteStep > 3}
                  />
                </button>
                <button
                  type='button'
                  onClick={() => setAddWebsiteStep(AddWebsiteStep.Fifth)}
                  className='text-left rtl:text-right'
                >
                  <StepFiveMenuItem
                    active={addWebsiteStep === AddWebsiteStep.Fifth}
                    completed={addWebsiteStep > 4}
                  />
                </button>
              </ul>

              <Button
                onClick={cancelFeedEvent}
                variant={'outline'}
                className='text-blue-500 hidden md:block'
              >
                {t('forms:cancel')}
              </Button>
            </div>
            <div className='flex-1'>
              <div
                className={
                  feedOpen ? 'hidden ' : 'h-full max-w-[100vw] overflow-auto'
                }
              >
                {children}
              </div>
              <div
                className={
                  feedOpen
                    ? 'flex-1 flex-col h-full rounded bg-muted/50 dark:bg-muted/50'
                    : 'hidden'
                }
              >
                <Progress
                  value={(addWebsiteStep + 1) * 20}
                  className='rounded-none'
                />
                <div className='px-6 py-6'>
                  <div className='bg-white dark:bg-muted/40 h-full rounded-lg'>
                    <StepBar
                      title={getStepTitle()}
                      step={addWebsiteStep}
                      setAddWebsiteStep={setAddWebsiteStep}
                    />
                    <StepOne active={addWebsiteStep === AddWebsiteStep.First} />
                    <StepTwo
                      active={addWebsiteStep === AddWebsiteStep.Second}
                    />
                    <StepThree
                      active={addWebsiteStep === AddWebsiteStep.Third}
                    />
                    <StepFour
                      active={addWebsiteStep === AddWebsiteStep.Fourth}
                    />
                    <StepFive
                      active={addWebsiteStep === AddWebsiteStep.Fifth}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
