const locales = [
  'en',
  'es',
  'de',
  'fr',
  'hi',
  'ja',
  'ru',
  'zh-CN',
  'zh-TW',
  'pt-BR',
  'pt-PT',
  'ar',
]

module.exports = {
  locales: locales,
  loader: false,
  defaultLocale: 'en',
  loadLocaleFrom: async (lang, ns) => {
    // optionally allow custom locales as needed. ex: `./_custom_locales/ar`
    if (process.env.CUSTOM_LOCALES_PATH) {
      const nextPath = `${process.env.CUSTOM_LOCALES_PATH}/${lang}/${ns}.json`

      return import(nextPath).then((m) => m.default)
    }

    const remotePath = process.env.CUSTOM_LOCALES_REMOTE_PATH

    // the remote path must contain the same remote setup for the json
    if (remotePath) {
      try {
        const res = await fetch(
          `${remotePath}${
            remotePath.endsWith('/') ? '' : '/'
          }${lang}/${ns}.json`,
          {
            headers: {
              authorization: process.env.CUSTOM_LOCALES_REMOTE_AUTH,
            },
          }
        )
        const json = await res.json()

        return json
      } catch (e) {
        console.error(e)
        return import(`./locales/${lang}/${ns}.json`).then((m) => m.default)
      }
    }

    return import(`./locales/${lang}/${ns}.json`).then((m) => m.default)
  },
  pages: {
    '*': ['common', 'lang', 'menu'],
    '/404': ['404'],
    '/': ['home', 'features-list'],
    '/admin': ['admin', 'auth', 'reset-password', 'forms'],
    '/about': ['about'],
    '/api-info': ['api-info'],
    '/auth-redirect': ['auth-redirect'],
    '/a11ywatchbot': ['a11ywatchbot'],
    '/atom': ['blog', 'guides'],
    '/careers': ['careers'],
    // GUIDES
    '/guides': ['guides'],
    '/guides/configure-crawler': ['guides', 'configure-crawler'],
    '/guides/cost-savings': ['guides', 'cost-savings'],
    '/guides/crawl-limit-pages': ['guides', 'crawl-limit-pages'],
    '/guides/litemode-js': ['guides', 'articles/litemode-js'],
    '/guides/ada-testing': ['guides', 'articles/ada-testing'],
    // BLOGS
    '/blog': ['blog'],
    '/blog/authors/jeff-mendez': ['blog', 'jeff-mendez'],
    '/blog/blacklives-matters': ['blog', 'blacklives-matter', 'jeff-mendez'],
    '/blog/categories/accessibility': ['blog', 'categories-accessibility'],
    '/blog/categories/api': ['blog', 'categories-api'],
    '/blog/categories/engineering': ['blog', 'categories-engineering'],
    '/blog/categories/wcag': ['blog', 'categories-wcag'],
    '/blog/create-blog-nextjs': ['blog', 'create-blog-nextjs', 'jeff-mendez'],
    '/blog/editable-custom-scripts': [
      'blog',
      'editable-custom-scripts',
      'jeff-mendez',
    ],
    '/blog/fast-efficient-and-accurate-web-accessibility-automation-tool': [
      'blog',
      'fast-efficient-and-accurate-web-accessibility-automation-tool',
      'jeff-mendez',
      'home',
    ],
    '/blog/next-js-to-astro': ['blog', 'next-js-to-astro', 'jeff-mendez'],
    '/blog/public-release': [
      'blog',
      'public-release',
      'jeff-mendez',
      'articles/litemode-js',
    ],
    '/blog/version-your-proto-definitions-for-stability': [
      'blog',
      'version-your-proto-definitions-for-stability',
      'jeff-mendez',
    ],
    '/blog/web-accessibility-api': [
      'blog',
      'web-accessibility-api',
      'jeff-mendez',
    ],
    '/blog/web-accessibility-checker': [
      'blog',
      'web-accessibility-checker',
      'jeff-mendez',
    ],
    '/blog/web-accessibility-monitoring': [
      'blog',
      'web-accessibility-monitoring',
      'jeff-mendez',
    ],
    '/brand-assets': ['brand-assets'],
    '/changelog': ['changelog', 'changelog-1'],
    '/climate': ['climate'],
    '/cloud': ['cloud'],
    '/contact': ['contact'],
    '/consulting': ['consulting'],
    '/dashboard': [
      'dashboard',
      'dashboard-empty',
      'dashboard-modal',
      'dashboard-theme',
      'dashboard-inputs',
      'menu',
      'errors',
      'edit-forms',
      'data-parse',
      'audit-form',
      'forms',
      'report-extra',
      'dash/website-cell',
      'dash/webhooks-cell',
      'vercel/deployment-status',
      'leaderboards-categories',
      'dash/tokens',
    ],
    '/dashboard/tokens': [
      'dash/tokens',
      'menu',
      'dashboard-theme',
      'dashboard',
    ],
    '/desktop': ['desktop'],
    '/demo': [
      'demo',
      'dashboard',
      'dashboard-empty',
      'dashboard-modal',
      'dashboard-theme',
      'dashboard-inputs',
      'menu',
      'errors',
      'edit-forms',
      'data-parse',
      'audit-form',
      'forms',
      'report-extra',
      'dash/website-cell',
      'dash/webhooks-cell',
      'leaderboards-categories',
    ],
    '/download-data-export': [
      'menu',
      'dashboard',
      'errors',
      'data-parse',
      'download-data-export',
    ],
    '/eula': ['eula'],
    '/faq': ['faq'],
    '/features': ['features', 'cli-features', 'features-list'],
    '/inactivity-policy': ['inactivity-policy'],
    '/login': ['auth', 'login', 'errors', 'dashboard'],
    '/leaderboards': [
      'leaderboards',
      'leaderboards-categories',
      'leaderboards-slugs',
    ],
    '/json': ['blog', 'guides'],
    '/open-source': ['oss'],
    '/payments': [
      'menu',
      'payments',
      'price',
      'errors',
      'data-parse',
      'faq',
      'credits-form',
      'dash/tokens',
    ],
    '/payments/complete': [
      'menu',
      'payments/complete',
      'price',
      'errors',
      'faq',
      'credits-form',
    ],
    '/pricing': ['pricing', 'price', 'faq', 'credits-form'],
    '/privacy': ['privacy'],
    '/profile': [
      'menu',
      'profile',
      'price',
      'settings',
      'dashboard',
      'errors',
      'data-parse',
      'forms',
      'usage-chart',
      'billing',
      'dash/tokens',
    ],
    '/logout': ['logout'],
    '/register': ['auth', 'register', 'errors', 'dashboard'],
    '/report': [
      'menu',
      'dashboard',
      'errors',
      'data-parse',
      'report-extra',
      'audit-form',
      'dash/tokens',
    ],
    '/reports': [
      'menu',
      'dashboard',
      'errors',
      'data-parse',
      'report-extra',
      'audit-form',
      'dash/tokens',
    ],
    '/reset-password': ['auth', 'errors', 'reset-password'],
    '/rss': ['blog', 'guides'],
    '/roadmap': ['roadmap'],
    '/settings': [
      'menu',
      'settings',
      'dashboard',
      'errors',
      'data-parse',
      'forms',
      'dashboard-theme',
      'dash/tokens',
    ],
    '/status': ['status'],
    '/oss-accessibility-benchmarks': ['oss-accessibility-benchmarks'],
    '/terms-of-service': ['terms-of-service'],
    '/visualizer': [
      'menu',
      'data-parse',
      'dashboard',
      'dashboard-modal',
      'errors',
    ],
    '/vercel/deployment-status': ['vercel/deployment-status'],
    '/vscode-extension': ['vscode-extension'],
    '/web-accessibility': ['web-accessibility', 'web-accessibility-links'],
    '/web-accessibility-services': [
      'web-accessibility-services',
      'features-list',
    ],
    '/website-accessibility-checker': [
      'website-accessibility-checker',
      'audit-form',
      'errors',
      'report-extra',
    ],
    '/why-us': ['home', 'why-us'],
    '/vercel-callback': ['vercel-callback'],
    '/vercel-integration': ['vercel-integration'],
  },
}
