import type { FC, PropsWithChildren, ReactNode } from 'react'
import { Card, CardContent } from '@app/@/components/ui/card'
import ms from 'ms'
import { Translate } from 'next-translate'

export const CrawlDuration: FC<
  PropsWithChildren<{
    count: number
    t: Translate
    children: string | Element | ReactNode | JSX.Element
  }>
> = ({ count, t, children }) => {
  return (
    <Card>
      <div className='flex gap-3 flex-1 pt-6'>
        <div className='flex-1'>
          <CardContent className='space-y-3'>
            <h4 className='font-bold text-lg flex gap-2 items-center'>
              {t('crawl-duration')}
            </h4>

            <h5 className='text-gray-600 dark:text-gray-300 leading-8 text-base'>
              {typeof count === 'undefined' && children
                ? children
                : ms(count || 0, { long: false })}
            </h5>

            <div className='font-bold flex gap-2 items-center text-xs invisible'>
              <div className='flex items-center gap-1 text-green-600 dark:text-green-700'>
                <svg
                  width='14'
                  height='14'
                  viewBox='0 0 12 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <mask
                    id='mask0_836_131300'
                    maskUnits='userSpaceOnUse'
                    x='0'
                    y='0'
                    width='24'
                    height='24'
                  >
                    <rect width='24' height='24' fill='#D9D9D9' />
                  </mask>
                  <g mask='url(#mask0_836_131300)'>
                    <path
                      d='M10.0004 4.7125L7.76289 6.9375C7.47122 7.22917 7.11706 7.375 6.70039 7.375C6.28372 7.375 5.92956 7.22917 5.63789 6.9375L5.05039 6.35C4.95872 6.25833 4.84206 6.2125 4.70039 6.2125C4.55872 6.2125 4.44206 6.25833 4.35039 6.35L2.05039 8.65C1.95872 8.74167 1.84206 8.7875 1.70039 8.7875C1.55872 8.7875 1.44206 8.74167 1.35039 8.65C1.25872 8.55833 1.21289 8.44167 1.21289 8.3C1.21289 8.15833 1.25872 8.04167 1.35039 7.95L3.65039 5.65C3.94206 5.35833 4.29622 5.2125 4.71289 5.2125C5.12956 5.2125 5.48372 5.35833 5.77539 5.65L6.35039 6.225C6.45039 6.325 6.56914 6.375 6.70664 6.375C6.84414 6.375 6.96289 6.325 7.06289 6.225L9.28789 4H8.50039C8.35872 4 8.23997 3.95208 8.14414 3.85625C8.04831 3.76042 8.00039 3.64167 8.00039 3.5C8.00039 3.35833 8.04831 3.23958 8.14414 3.14375C8.23997 3.04792 8.35872 3 8.50039 3H10.5004C10.6421 3 10.7608 3.04792 10.8566 3.14375C10.9525 3.23958 11.0004 3.35833 11.0004 3.5V5.5C11.0004 5.64167 10.9525 5.76042 10.8566 5.85625C10.7608 5.95208 10.6421 6 10.5004 6C10.3587 6 10.24 5.95208 10.1441 5.85625C10.0483 5.76042 10.0004 5.64167 10.0004 5.5V4.7125Z'
                      fill='#08AF04'
                    />
                  </g>
                </svg>
                <p className='text-xs'>{count}</p>
              </div>
              {t('vs-prev')}
            </div>
          </CardContent>
        </div>
        <div className='px-4'>
          <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              width='48'
              height='48'
              rx='24'
              fill='#F9AE40'
              fillOpacity='0.1'
            />
            <path
              d='M24 25.75C23.59 25.75 23.25 25.41 23.25 25V20C23.25 19.59 23.59 19.25 24 19.25C24.41 19.25 24.75 19.59 24.75 20V25C24.75 25.41 24.41 25.75 24 25.75Z'
              fill='#F9AE40'
            />
            <path
              d='M24 34.75C18.76 34.75 14.5 30.49 14.5 25.25C14.5 20.01 18.76 15.75 24 15.75C29.24 15.75 33.5 20.01 33.5 25.25C33.5 25.66 33.16 26 32.75 26C32.34 26 32 25.66 32 25.25C32 20.84 28.41 17.25 24 17.25C19.59 17.25 16 20.84 16 25.25C16 29.66 19.59 33.25 24 33.25C24.41 33.25 24.75 33.59 24.75 34C24.75 34.41 24.41 34.75 24 34.75Z'
              fill='#F9AE40'
            />
            <path
              d='M27 14.75H21C20.59 14.75 20.25 14.41 20.25 14C20.25 13.59 20.59 13.25 21 13.25H27C27.41 13.25 27.75 13.59 27.75 14C27.75 14.41 27.41 14.75 27 14.75Z'
              fill='#F9AE40'
            />
            <path
              d='M28.1504 34.0198C27.8004 34.0198 27.4804 33.9398 27.1904 33.7698C26.5304 33.3898 26.1504 32.6198 26.1504 31.6598V29.3498C26.1504 28.3898 26.5304 27.6198 27.1904 27.2398C27.8504 26.8598 28.7004 26.9198 29.5304 27.3898L31.5304 28.5498C32.3604 29.0298 32.8404 29.7398 32.8404 30.4998C32.8404 31.2598 32.3604 31.9698 31.5304 32.4498L29.5304 33.6098C29.0704 33.8798 28.6004 34.0198 28.1504 34.0198ZM28.1604 28.4798C28.0804 28.4798 28.0004 28.4998 27.9404 28.5298C27.7604 28.6298 27.6504 28.9398 27.6504 29.3398V31.6498C27.6504 32.0498 27.7604 32.3598 27.9404 32.4598C28.1204 32.5598 28.4404 32.5098 28.7804 32.3098L30.7804 31.1498C31.1304 30.9498 31.3404 30.6998 31.3404 30.4998C31.3404 30.2998 31.1304 30.0498 30.7804 29.8498L28.7804 28.6898C28.5504 28.5498 28.3304 28.4798 28.1604 28.4798Z'
              fill='#F9AE40'
            />
          </svg>
        </div>
      </div>
    </Card>
  )
}
