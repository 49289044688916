import { Translate } from 'next-translate'

export const LeaderboardsText = ({
  t,
  textHidden,
}: {
  t: Translate
  textHidden?: boolean
}) => {
  return (
    <>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.67 22.75H2C1.59 22.75 1.25 22.41 1.25 22V16C1.25 14.48 2.48 13.25 4 13.25H8.67C9.08 13.25 9.42 13.59 9.42 14V22C9.42 22.41 9.08 22.75 8.67 22.75ZM2.75 21.25H7.92V14.75H4C3.31 14.75 2.75 15.31 2.75 16V21.25Z'
          fill='#255769'
        />
        <path
          d='M15.3302 22.75H8.66016C8.25016 22.75 7.91016 22.41 7.91016 22V12C7.91016 10.48 9.14016 9.25 10.6602 9.25H13.3302C14.8502 9.25 16.0802 10.48 16.0802 12V22C16.0802 22.41 15.7502 22.75 15.3302 22.75ZM9.42015 21.25H14.5902V12C14.5902 11.31 14.0302 10.75 13.3402 10.75H10.6702C9.98015 10.75 9.42015 11.31 9.42015 12V21.25Z'
          fill='#255769'
        />
        <path
          d='M22.0001 22.75H15.3301C14.9201 22.75 14.5801 22.41 14.5801 22V17C14.5801 16.59 14.9201 16.25 15.3301 16.25H20.0001C21.5201 16.25 22.7501 17.48 22.7501 19V22C22.7501 22.41 22.4101 22.75 22.0001 22.75ZM16.0801 21.25H21.2501V19C21.2501 18.31 20.6901 17.75 20.0001 17.75H16.0801V21.25Z'
          fill='#255769'
        />
        <path
          d='M13.6989 8.35023C13.4589 8.35023 13.1589 8.28021 12.8189 8.08021L11.9989 7.59022L11.1889 8.07023C10.3689 8.56023 9.82892 8.27023 9.62892 8.13023C9.42892 7.99023 8.99891 7.55023 9.20891 6.63023L9.39892 5.80021L8.71892 5.12022C8.29892 4.70022 8.14891 4.20021 8.29891 3.74021C8.44891 3.28021 8.85891 2.96021 9.43891 2.86021L10.3089 2.71021L10.7989 1.73023C11.3389 0.660234 12.6489 0.660234 13.1789 1.73023L13.6689 2.71021L14.5389 2.86021C15.1189 2.96021 15.5389 3.28021 15.6789 3.74021C15.8289 4.20021 15.6689 4.70022 15.2589 5.12022L14.5789 5.80021L14.7689 6.63023C14.9789 7.56023 14.5489 7.99024 14.3489 8.14024C14.2589 8.22024 14.0289 8.35023 13.6989 8.35023ZM11.9989 6.08021C12.2389 6.08021 12.4789 6.14023 12.6789 6.26023L13.2389 6.59022L13.1189 6.05021C13.0189 5.63021 13.1689 5.12022 13.4789 4.81022L13.9889 4.30021L13.3589 4.19023C12.9589 4.12023 12.5689 3.83022 12.3889 3.47022L11.9989 2.72022L11.6189 3.47022C11.4389 3.83022 11.0489 4.12023 10.6489 4.19023L10.0189 4.29023L10.5289 4.80021C10.8389 5.11021 10.9789 5.62023 10.8889 6.04023L10.7689 6.58021L11.3289 6.25022C11.5189 6.13022 11.7589 6.08021 11.9989 6.08021Z'
          fill='#255769'
        />
      </svg>
      <span className={`${textHidden ? 'hidden' : ''}`}>
        {t('leaderboards')}
      </span>
    </>
  )
}
