import { Button } from '@app/components/general'
import { useWebsiteContext } from '@app/components/providers/website'
import { ScanFrequency } from '@app/data/local/useWebsiteForm'

const btnStyle =
  'border-2 flex flex-col items-center place-content-center gap-3 py-4 px-2 rounded-xl w-full relative font-semibold md:min-h-[35vh] md:text-2xl'

const selectedCheckStyle =
  'flex items-center place-content-center rounded-full border-blue-50 bg-blue-500 h-8 w-8'

const selectedTextStyle =
  'border-blue-400 dark:border-blue-700 text-blue-800 bg-blue-50 font-bold dark:bg-blue-800 dark:text-blue-100'

// Initial step form
export const StepFour = ({ active }: { active: boolean }) => {
  const { addWebsiteForm } = useWebsiteContext()

  const scanFrequency = addWebsiteForm.scanFrequency

  const defaultSelected = scanFrequency === ScanFrequency.Default

  const onSetDefaultFrequencyEvent = () => {
    addWebsiteForm.setScanFrequency(ScanFrequency.Default)
  }

  const onSetManualFrequencyEvent = () => {
    addWebsiteForm.setScanFrequency(ScanFrequency.Manual)
  }

  return (
    <div className={`px-5 h-full${!active ? ' hidden' : ''}`}>
      <div className='flex flex-1 flex-col md:flex-row gap-3 items-center h-full w-full'>
        <Button
          type={'button'}
          className={`${btnStyle}${defaultSelected ? ` ${selectedTextStyle}` : ' text-gray-500 dark:text-gray-300'}`}
          onClick={onSetDefaultFrequencyEvent}
        >
          {defaultSelected ? (
            <div className='absolute top-3 right-3'>
              <div className={selectedCheckStyle}>
                <svg
                  width='19'
                  height='15'
                  viewBox='0 0 19 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className={
                    defaultSelected ? 'fill-blue-400' : 'fill-gray-400'
                  }
                >
                  <path
                    d='M6.09193 10.8018L16.1457 0.48105C16.4581 0.16035 16.8558 0 17.3386 0C17.8214 0 18.219 0.16035 18.5314 0.48105C18.8438 0.801749 19 1.20991 19 1.70554C19 2.20117 18.8438 2.60933 18.5314 2.93003L7.28475 14.4752C6.94395 14.8251 6.54634 15 6.09193 15C5.63752 15 5.23991 14.8251 4.8991 14.4752L0.46861 9.92711C0.156203 9.60641 0 9.19825 0 8.70262C0 8.207 0.156203 7.79883 0.46861 7.47813C0.781016 7.15743 1.17862 6.99708 1.66143 6.99708C2.14425 6.99708 2.54185 7.15743 2.85426 7.47813L6.09193 10.8018Z'
                    fill='white'
                  />
                </svg>
              </div>
            </div>
          ) : null}
          <svg
            width='40'
            height='41'
            viewBox='0 0 40 41'
            xmlns='http://www.w3.org/2000/svg'
            className={!defaultSelected ? 'fill-blue-400' : 'fill-gray-400'}
          >
            <path d='M3.33301 16.7497C2.64967 16.7497 2.08301 16.183 2.08301 15.4997V11.333C2.08301 6.49967 6.01634 2.58301 10.833 2.58301H14.9997C15.683 2.58301 16.2497 3.14967 16.2497 3.83301C16.2497 4.51634 15.683 5.08301 14.9997 5.08301H10.833C7.38301 5.08301 4.58301 7.88301 4.58301 11.333V15.4997C4.58301 16.183 4.01634 16.7497 3.33301 16.7497Z' />
            <path d='M36.6667 16.7497C35.9833 16.7497 35.4167 16.183 35.4167 15.4997V11.333C35.4167 7.88301 32.6167 5.08301 29.1667 5.08301H25C24.3167 5.08301 23.75 4.51634 23.75 3.83301C23.75 3.14967 24.3167 2.58301 25 2.58301H29.1667C33.9833 2.58301 37.9167 6.49967 37.9167 11.333V15.4997C37.9167 16.183 37.35 16.7497 36.6667 16.7497Z' />
            <path d='M29.167 38.417H26.667C25.9837 38.417 25.417 37.8503 25.417 37.167C25.417 36.4837 25.9837 35.917 26.667 35.917H29.167C32.617 35.917 35.417 33.117 35.417 29.667V27.167C35.417 26.4837 35.9837 25.917 36.667 25.917C37.3503 25.917 37.917 26.4837 37.917 27.167V29.667C37.917 34.5003 33.9837 38.417 29.167 38.417Z' />
            <path d='M14.9997 38.4167H10.833C6.01634 38.4167 2.08301 34.5 2.08301 29.6667V25.5C2.08301 24.8167 2.64967 24.25 3.33301 24.25C4.01634 24.25 4.58301 24.8167 4.58301 25.5V29.6667C4.58301 33.1167 7.38301 35.9167 10.833 35.9167H14.9997C15.683 35.9167 16.2497 36.4833 16.2497 37.1667C16.2497 37.85 15.683 38.4167 14.9997 38.4167Z' />
            <path d='M23.3337 30.9163H16.667C12.6337 30.9163 10.417 28.6997 10.417 24.6663V16.333C10.417 12.2997 12.6337 10.083 16.667 10.083H23.3337C27.367 10.083 29.5837 12.2997 29.5837 16.333V24.6663C29.5837 28.6997 27.367 30.9163 23.3337 30.9163ZM16.667 12.583C14.0337 12.583 12.917 13.6997 12.917 16.333V24.6663C12.917 27.2997 14.0337 28.4163 16.667 28.4163H23.3337C25.967 28.4163 27.0837 27.2997 27.0837 24.6663V16.333C27.0837 13.6997 25.967 12.583 23.3337 12.583H16.667Z' />
            <path d='M31.6663 21.75H8.33301C7.64967 21.75 7.08301 21.1833 7.08301 20.5C7.08301 19.8167 7.64967 19.25 8.33301 19.25H31.6663C32.3497 19.25 32.9163 19.8167 32.9163 20.5C32.9163 21.1833 32.3497 21.75 31.6663 21.75Z' />
          </svg>
          <div>Default (From Account Settings)</div>
          <div className='text-sm'>
            Use the default scan frequency set in your account Settings,
            typically set to monthly.
          </div>
        </Button>
        <Button
          onClick={onSetManualFrequencyEvent}
          type={'button'}
          className={`${btnStyle}${!defaultSelected ? ` ${selectedTextStyle}` : ' text-gray-500 dark:text-gray-300'}`}
        >
          {!defaultSelected ? (
            <div className='absolute top-3 right-3'>
              <div className={selectedCheckStyle}>
                <svg
                  width='19'
                  height='15'
                  viewBox='0 0 19 15'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                  className={
                    !defaultSelected ? 'fill-blue-400' : 'fill-gray-400'
                  }
                >
                  <path
                    d='M6.09193 10.8018L16.1457 0.48105C16.4581 0.16035 16.8558 0 17.3386 0C17.8214 0 18.219 0.16035 18.5314 0.48105C18.8438 0.801749 19 1.20991 19 1.70554C19 2.20117 18.8438 2.60933 18.5314 2.93003L7.28475 14.4752C6.94395 14.8251 6.54634 15 6.09193 15C5.63752 15 5.23991 14.8251 4.8991 14.4752L0.46861 9.92711C0.156203 9.60641 0 9.19825 0 8.70262C0 8.207 0.156203 7.79883 0.46861 7.47813C0.781016 7.15743 1.17862 6.99708 1.66143 6.99708C2.14425 6.99708 2.54185 7.15743 2.85426 7.47813L6.09193 10.8018Z'
                    fill='white'
                  />
                </svg>
              </div>
            </div>
          ) : null}
          <svg
            width='40'
            height='41'
            viewBox='0 0 40 41'
            xmlns='http://www.w3.org/2000/svg'
            className={!defaultSelected ? 'fill-blue-400' : 'fill-gray-400'}
          >
            <path d='M3.33301 16.7497C2.64967 16.7497 2.08301 16.183 2.08301 15.4997V11.333C2.08301 6.49967 6.01634 2.58301 10.833 2.58301H14.9997C15.683 2.58301 16.2497 3.14967 16.2497 3.83301C16.2497 4.51634 15.683 5.08301 14.9997 5.08301H10.833C7.38301 5.08301 4.58301 7.88301 4.58301 11.333V15.4997C4.58301 16.183 4.01634 16.7497 3.33301 16.7497Z' />
            <path d='M36.6667 16.7497C35.9833 16.7497 35.4167 16.183 35.4167 15.4997V11.333C35.4167 7.88301 32.6167 5.08301 29.1667 5.08301H25C24.3167 5.08301 23.75 4.51634 23.75 3.83301C23.75 3.14967 24.3167 2.58301 25 2.58301H29.1667C33.9833 2.58301 37.9167 6.49967 37.9167 11.333V15.4997C37.9167 16.183 37.35 16.7497 36.6667 16.7497Z' />
            <path d='M29.167 38.417H26.667C25.9837 38.417 25.417 37.8503 25.417 37.167C25.417 36.4837 25.9837 35.917 26.667 35.917H29.167C32.617 35.917 35.417 33.117 35.417 29.667V27.167C35.417 26.4837 35.9837 25.917 36.667 25.917C37.3503 25.917 37.917 26.4837 37.917 27.167V29.667C37.917 34.5003 33.9837 38.417 29.167 38.417Z' />
            <path d='M14.9997 38.4167H10.833C6.01634 38.4167 2.08301 34.5 2.08301 29.6667V25.5C2.08301 24.8167 2.64967 24.25 3.33301 24.25C4.01634 24.25 4.58301 24.8167 4.58301 25.5V29.6667C4.58301 33.1167 7.38301 35.9167 10.833 35.9167H14.9997C15.683 35.9167 16.2497 36.4833 16.2497 37.1667C16.2497 37.85 15.683 38.4167 14.9997 38.4167Z' />
            <path d='M23.3337 30.9163H16.667C12.6337 30.9163 10.417 28.6997 10.417 24.6663V16.333C10.417 12.2997 12.6337 10.083 16.667 10.083H23.3337C27.367 10.083 29.5837 12.2997 29.5837 16.333V24.6663C29.5837 28.6997 27.367 30.9163 23.3337 30.9163ZM16.667 12.583C14.0337 12.583 12.917 13.6997 12.917 16.333V24.6663C12.917 27.2997 14.0337 28.4163 16.667 28.4163H23.3337C25.967 28.4163 27.0837 27.2997 27.0837 24.6663V16.333C27.0837 13.6997 25.967 12.583 23.3337 12.583H16.667Z' />
            <path d='M31.6663 21.75H8.33301C7.64967 21.75 7.08301 21.1833 7.08301 20.5C7.08301 19.8167 7.64967 19.25 8.33301 19.25H31.6663C32.3497 19.25 32.9163 19.8167 32.9163 20.5C32.9163 21.1833 32.3497 21.75 31.6663 21.75Z' />
          </svg>
          <div>Choose Manually</div>
          <div className='text-sm'>
            Override and choose your own scan frequency that best suits your
            needs.
          </div>
        </Button>
      </div>
    </div>
  )
}
