import { ChangeEventHandler, useState } from 'react'
import { InfoBlock } from '../cells'
import { inputStyle } from '../cells/blocks/input-style'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { InputBoxProps } from '@app/types/dashboard'
import { Input } from '@app/@/components/ui/input'

interface ProxyProps extends InputBoxProps {
  value?: string
  demo?: boolean
}

interface ProxyInputProps extends ProxyProps {
  onChangeProxy?: ChangeEventHandler<HTMLInputElement>
  text: string
}

export const ProxyBoxInput = ({
  value,
  url,
  activeSubscription,
  onChangeProxy,
  text,
  tooltip = true,
  translation,
  id,
  dialog,
}: ProxyInputProps) => {
  const { t } = useTranslation(translation || 'dashboard')

  const [visible, setVisible] = useState<boolean>(false)
  const onFocusEvent = () => setVisible(true)
  const onBlurEvent = () => setVisible(false)
  const proxyLabel = `${url || id || ''}-proxy-id`

  return (
    <InfoBlock
      tipID={`${url}-proxy-tip`}
      toolTip={tooltip ? t('dashboard-inputs:proxy-tip') : ''}
      noPx={dialog}
      labelTip={<label htmlFor={proxyLabel}>{text}</label>}
      noClass
    >
      <Input
        className={inputStyle(activeSubscription, dialog)}
        onChange={onChangeProxy}
        onFocus={onFocusEvent}
        onBlur={onBlurEvent}
        defaultValue={visible ? value : (value && '*************') || undefined}
        id={proxyLabel}
        placeholder={text}
        type='url'
        name={'proxy'}
      />
    </InfoBlock>
  )
}
