import React from 'react'
import { getMainDefinition } from 'apollo-utilities'
import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  split,
  InMemoryCache,
  RequestHandler,
  createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { UserManager } from '@app/managers'
import { AppConfig } from '@app/configs/app-config'
import { resolvers } from './resolvers'
import { WebSocketLink } from './ws-link'
import { toast } from '@app/@/components/ui/use-toast'

const createLink = (): ApolloLink | RequestHandler => {
  const httpLink = createHttpLink({
    uri: AppConfig.graphQLUrl,
    credentials: 'include',
  })

  const authLink = setContext((_: any, { headers }: any) => {
    return {
      headers: Object.assign({}, headers, {
        authorization: UserManager?.token ? `Bearer ${UserManager?.token}` : '',
      }),
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }: any) => {
    if (
      typeof window !== 'undefined' &&
      window.location.pathname !== '/api-info'
    ) {
      let graphErrors = ''
      graphQLErrors?.forEach(({ message }: { message?: string }) => {
        if (message) {
          const invalidSig = message.startsWith(
            'Context creation failed: invalid signature'
          )

          const invalidSignature =
            invalidSig || message.includes('Error please sign in to continue')

          // TODO: CLEAR AUTH SS FOR USER AND redirect CS
          if (message.includes('JWT:') || invalidSignature) {
            UserManager.clearUser()
            window.location.href = '/logout'
          }

          // todo: get lang code for re-login
          graphErrors += `${invalidSignature ? 'Please re-login' : message}\n`
        }
      })

      if (graphErrors) {
        // ignore api info error for displaying jwt
        requestAnimationFrame(() => {
          toast({
            title: 'Error',
            description: graphErrors.trim(),
          })
        })
      }

      if (networkError) {
        console.error(`[Network error]:`, networkError)
      }
    }
  })

  let httpSplit = httpLink

  if (typeof window !== 'undefined') {
    const wsLink = new WebSocketLink({
      url: AppConfig.webSocketUrl,
      connectionParams: {
        authorization: UserManager?.token ? `Bearer ${UserManager?.token}` : '',
      },
      retryAttempts: 10,
    })

    httpSplit = split(
      ({ query }: any) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink
    )
  }

  return ApolloLink.from([errorLink, authLink, httpSplit])
}

function createApolloClient(initialState: any = {}) {
  const link = createLink()

  return new ApolloClient({
    ssrMode: false,
    link: link as ApolloLink,
    cache: new InMemoryCache({
      dataIdFromObject: (object: any) => object._id || object.id || null,
    }).restore(initialState),
    resolvers,
  })
}

export function withApollo(
  PageComponent: any,
  defaultProps: { ssr: boolean } = { ssr: false }
) {
  const WithApollo = ({ apolloClient, apolloState, ...pageProps }: any) => {
    const client = apolloClient || createApolloClient(apolloState)
    return (
      <ApolloProvider client={client}>
        <PageComponent {...pageProps} />
      </ApolloProvider>
    )
  }

  WithApollo.displayName = PageComponent.name || PageComponent.displayName

  if (PageComponent.meta) {
    WithApollo.meta = PageComponent.meta
  }

  if (defaultProps.ssr || PageComponent.getInitialProps) {
    WithApollo.getInitialProps = async (ctx: any) => {
      const { AppTree } = ctx

      if (!ctx.apolloClient) {
        ctx.apolloClient = createApolloClient()
      }

      const apolloClient = ctx?.apolloClient

      let pageProps = {}

      if (PageComponent.getInitialProps) {
        try {
          pageProps = await PageComponent.getInitialProps(ctx)
        } catch (e) {
          console.error(e)
        }
      }

      if (typeof window === 'undefined') {
        if (ctx?.res?.finished) {
          return pageProps
        }

        if (defaultProps.ssr) {
          try {
            const { getDataFromTree } = await import('@apollo/client/react/ssr')

            await getDataFromTree(
              <AppTree
                pageProps={{
                  ...pageProps,
                  apolloClient,
                }}
              />
            )
          } catch (error) {
            console.error('Error while running `getDataFromTree`', error)
          }
        }
      }

      // todo: remove freeze

      return Object.freeze(
        Object.assign({}, pageProps, {
          apolloState: apolloClient.cache.extract(),
        })
      )
    }
  }

  return WithApollo
}
