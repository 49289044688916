import React, { useState, useCallback, useMemo } from 'react'
import { domainList as dmList } from '@app/utils'
import { useInputActions, useInputHeader } from '@app/components/general/hooks'
import {
  AccessibilityStandardKeys,
  Standard,
} from '@app/components/general/select/select-input'
import { useAuthContext } from '@app/components/providers/auth'
import {
  SitemapKeys,
  SiteMapOption,
} from '@app/components/general/select/sitemap-input'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { Theme, ThemeKeys } from '@app/components/general/select/theme-input'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { useKayleRules } from '@app/data/formatters/use-kayle-rules'
import { useToast } from '@app/@/components/ui/use-toast'

const domainList = [...dmList, 'none']

interface InputHead {
  key: string
  value: string
}

// validate the headers inputs and send
const validateHeaders = (object: InputHead[]) => {
  const headers = []

  let i = 0

  // allow up to 50 headers
  for (const p of object) {
    const { key, value } = p

    if (key && value) {
      headers.push(p)
    }
    if (i > 50) {
      break
    }
    i++
  }

  return headers
}

export interface FormDialogProps {
  okPress?: (a: any) => void
  addWebsite?: (a: any, b: boolean) => Promise<void>
  buttonStyles?: string
  icon?: boolean | 'add' // show btn with icon
  iconButton?: boolean
  dialogDisabled?: boolean
  translation?: string
}

// scan frequency for crons.
export enum ScanFrequency {
  Default,
  Manual,
}

// form hook to add a new website - this a pure hook capable of being used offline
export const useAddWebsiteForm = ({
  okPress,
  dialogDisabled,
  translation,
  addWebsite,
}: FormDialogProps) => {
  const { t, lang } = useTranslation(translation || 'dashboard')
  const { account } = useAuthContext()
  const activeSubscription = account?.activeSubscription

  // custom state
  const [open, setOpen] = useState<boolean>(false)
  const [websitUrl, setUrl] = useState<string>('')
  const [rootElement, setRootSelector] = useState<string>('')
  const [hideElements, setHiddenElements] = useState<string>('')
  const [warningsEnabled, setWarningsEnabled] = useState<boolean>(true)
  const [mobileViewport, setMobile] = useState<boolean>(false)
  const [subdomains, setSubdomains] = useState<boolean>(false)
  const [sitemap, setSitemap] = useState<number>(0)
  const [tld, setTld] = useState<boolean>(false)
  const [ua, setUserAgent] = useState<string>('')
  const [proxy, setProxy] = useState<string>('')
  const [standard, setWCAGStandard] = useState<AccessibilityStandardKeys>(
    Standard[Standard.WCAG2AA] as AccessibilityStandardKeys
  )
  const [theme, setTheme] = useState<ThemeKeys>(
    Theme[Theme.system] as ThemeKeys
  )
  const [robots, setRobots] = useState<boolean>(!!activeSubscription)
  const [runners, setRunners] = useState<string[]>([])
  const [monitoringEnabled, setMonitoring] =
    useState<boolean>(!!activeSubscription)
  const [crawlDelay, setCrawlDelay] = useState<number>()
  const [crawlLimit, setCrawlLimit] = useState<number>()
  const [blacklist, setBlackList] = useState<string>()
  const { ignore, setIgnore, ignoreList, onLoadRulesEvent } = useKayleRules({
    runners,
    lang,
  })
  const [profileVisible, setVisible] = useState<boolean>(account.profileVisible) // public to leaderboard
  const [urlListVisible, setToggleUrlList] = useState<boolean>(false)
  const [websiteList, setWebsiteList] = useState<string[]>([])
  const [scanFrequency, setScanFrequency] = useState<ScanFrequency>(
    ScanFrequency.Default
  )

  const { toast } = useToast()

  const headers = useInputHeader()
  const actions = useInputActions()

  const handleClickOpen = useCallback(() => setOpen(true), [setOpen])

  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value)
    },
    [setUrl]
  )

  const onBlacklistChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBlackList(event.target.value)
    },
    [setBlackList]
  )

  const onStandardChange = useCallback(
    (event: React.ChangeEvent<any>) => setWCAGStandard(event.target.value),
    [setWCAGStandard]
  )

  const onThemeChange = useCallback(
    (event: React.ChangeEvent<any>) => setTheme(event.target.value),
    [setTheme]
  )

  const onCrawlLimitChange = useCallback(
    (event: React.ChangeEvent<any>) =>
      setCrawlLimit(Number(event.target.value)),
    [setCrawlLimit]
  )

  const onRunnerEvent = useCallback(
    (event: string[]) => setRunners(event),
    [setRunners]
  )
  const handleClose = useCallback(() => {
    setOpen(false)
    setUrl('')
  }, [setOpen, setUrl])

  const onToggleWarnings = useCallback(
    () => setWarningsEnabled((x) => !x),
    [setWarningsEnabled]
  )

  const onToggleUrlListEvent = () => setToggleUrlList((x) => !x)

  // fields
  const customActions = actions.customActions
  const customActionFields = actions.customFields
  // headers
  const customHeader = headers.customHeader
  const customFields = headers.customFields

  const crawlValidUrl = t('enter-valid-url')

  const defaultRunnerData = useMemo(
    () => runners.map((runner) => runner),
    [runners]
  )

  // main form submission of data
  const submit = useCallback(
    async (event: any) => {
      event?.preventDefault()

      // display demo dialog message
      if (dialogDisabled) {
        return
      }

      const websiteTargetUrls = !urlListVisible
        ? websitUrl?.trim().split(',')
        : websiteList

      for (let i = 0; i < websiteTargetUrls.length; i++) {
        const u = websiteTargetUrls[i]
        const _websitUrl = u

        if (!_websitUrl || (_websitUrl && _websitUrl === 'undefined')) {
          // prevent empty
          toast({
            title: crawlValidUrl,
            variant: 'destructive',
          })
          continue
        }

        handleClose()

        let cleanUrl = String(_websitUrl).replace(/^(?:https?:\/\/)?/i, '')

        let tpt = 'https'

        if (_websitUrl.includes('http://')) {
          tpt = 'http'
        }

        let urlBase = cleanUrl.includes('://') ? '' : `://`
        let blockExt = false

        if (cleanUrl.includes('localhost:')) {
          blockExt = true
        }

        // determine whether to add an extension or not
        const ex =
          blockExt ||
          cleanUrl.includes('.') ||
          domainList.some((element: any) => cleanUrl.includes(element))
            ? ''
            : '.com'

        const websiteUrlTarget = `${tpt}${urlBase}${cleanUrl}${ex}`.replaceAll(
          ' ',
          ''
        )

        const websiteCustomHeaders = customHeader
          ? validateHeaders(customFields)
          : null

        // make all paths start with slash
        const websiteActions = customActions
          ? customActionFields.map((items) => {
              const pathName =
                items.path && items.path[0] === '/'
                  ? items.path
                  : `/${items.path}`

              return {
                ...items,
                path: pathName,
              }
            })
          : null

        const params = {
          url: websiteUrlTarget.trim(),
          customHeaders: websiteCustomHeaders,
          mobile: mobileViewport,
          ua,
          standard,
          actions: websiteActions,
          robots,
          subdomains,
          tld,
          runners,
          proxy,
          sitemap,
          monitoringEnabled: activeSubscription ? monitoringEnabled : true,
          crawlDelay,
          rootElement,
          hideElements: hideElements ? hideElements.split(',') : [],
          theme: [theme],
          warningsEnabled,
          ignore,
          crawlLimit,
          blacklist:
            blacklist && blacklist.length ? blacklist.split(',') : undefined,
          profileVisible,
        }

        // we need to fix the session re-routing for multi form uploads.
        if (!activeSubscription) {
          if (typeof sessionStorage !== 'undefined') {
            // use-local storage instead with a date stamp - session does not persist between docs
            sessionStorage.setItem(
              '@app/subscribe-website',
              JSON.stringify(params)
            )
          }
          window.location.href = routeParseAbsolute('/payments', lang)
          return
        }

        // CLOSE pre-optimistic prevent dialog unmount state error
        if (i + 1 == websiteTargetUrls.length) {
          setWebsiteList([])
          if (urlListVisible) {
            setToggleUrlList(false)
          }
        }

        if (okPress && typeof okPress === 'function') {
          try {
            await okPress(params)
          } catch (e) {
            console.error(e)
          }
        } else if (addWebsite && typeof addWebsite === 'function') {
          try {
            await addWebsite(
              {
                variables: params,
              },
              !i // first one display tooltip
            )
          } catch (e) {
            console.error(e)
          }
        }

        // reset entire state.
        setUrl('')
        setRootSelector('')
        setHiddenElements('')
        setUserAgent('')
        setWarningsEnabled(true)
        setMobile(false)
        setSubdomains(false)
        setSitemap(0)
        setMonitoring(!!activeSubscription)
        setCrawlDelay(undefined)
        setCrawlLimit(undefined)
        setBlackList(undefined)
        setIgnore([])
        setWebsiteList([])
        setScanFrequency(ScanFrequency.Default)
        setToggleUrlList(false)
        setVisible(!!account.profileVisible)
      }
    },
    [
      lang,
      crawlValidUrl,
      crawlLimit,
      blacklist,
      handleClose,
      addWebsite,
      okPress,
      setIgnore,
      websitUrl,
      mobileViewport,
      standard,
      ua,
      ignore,
      customFields,
      customHeader,
      customActionFields,
      customActions,
      robots,
      subdomains,
      tld,
      runners,
      proxy,
      sitemap,
      monitoringEnabled,
      crawlDelay,
      activeSubscription,
      rootElement,
      hideElements,
      theme,
      dialogDisabled,
      warningsEnabled,
      profileVisible,
      toast,
      urlListVisible,
      websiteList,
      setToggleUrlList,
      account,
    ]
  )

  const onChangeUA = (event: React.ChangeEvent<HTMLInputElement>) =>
    setUserAgent(event.target.value)

  const onChangeProxy = (event: React.ChangeEvent<HTMLInputElement>) =>
    setProxy(event.target.value)

  const onChangeRootSelector = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = event?.target?.value
    const intValue = nextValue && isNaN(parseInt(nextValue[0]))
    const symbolBlock =
      nextValue &&
      ['*', '&', '^', '%', '$', '@', '!', '(', '}', ')', '{', '|'].includes(
        nextValue[0]
      )
    // starts with char or #,., element

    if ((nextValue && !intValue) || symbolBlock) {
      return
    }

    setRootSelector(nextValue)
  }

  const onChangeHideElements = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = event?.target?.value
      const intValue = nextValue && isNaN(parseInt(nextValue[0]))
      const symbolBlock =
        nextValue &&
        ['*', '&', '^', '%', '$', '@', '!', '(', '}', ')', '{', '|'].includes(
          nextValue[0]
        )
      // starts with char or #,., element

      if ((nextValue && !intValue) || symbolBlock) {
        return
      }

      setHiddenElements(nextValue)
    },
    [setHiddenElements]
  )

  const onChangeCrawlDelay = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setCrawlDelay(Math.min(Number(event.target.value), 2000)),
    [setCrawlDelay]
  )

  // toggle actions form
  const onChangeActionsEvent = () =>
    actions.setCustomActions((v: boolean) => !v)

  // toggle headers form
  const onChangeHeadersEvent = () => headers.setCustomHeader((v: boolean) => !v)

  const onMobileViewPortEvent = () => setMobile(!mobileViewport)

  const onChangeRobotsEvent = () => setRobots((a: boolean) => !a)

  const onChangeSubdomainsEvent = () => setSubdomains((a: boolean) => !a)

  const onChangeTldEvent = () => setTld((a: boolean) => !a)

  const onChangeWebsiteVisiblity = () => setVisible((a: boolean) => !a)

  const onChangeSitemapEvent = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSitemap(SiteMapOption[event.target.value as SitemapKeys])

  const onChangeMonitoringEvent = () => setMonitoring((a: boolean) => !a)
  const closeModalText = t('common:close-modal')

  const storageless = [11, 20, 0].includes(account?.role || 0)

  const submitDisabled = urlListVisible ? !websiteList?.length : !websitUrl

  return {
    submitDisabled,
    closeModalText,
    storageless,
    defaultRunnerData,
    setIgnore,
    ignoreList,
    ignore,
    onLoadRulesEvent,
    open,
    setOpen,
    submit,
    headers,
    actions,
    onToggleUrlListEvent,
    onToggleWarnings,
    onRunnerEvent,
    onCrawlLimitChange,
    onStandardChange,
    handleClickOpen,
    setScanFrequency,
    scanFrequency,
    standard,
    profileVisible,
    mobileViewport,
    crawlDelay,
    crawlLimit,
    urlListVisible,
    onBlacklistChange,
    onChangeActionsEvent,
    onChangeCrawlDelay,
    onChangeCrawlDelayEvent: onChangeCrawlDelay,
    onChangeHeadersEvent,
    onChangeProxyEvent: onChangeProxy,
    onChangeProxy,
    onChangeMonitoringEvent,
    onChangeRobotsEvent,
    onChangeRootSelector,
    onChangeSitemapEvent,
    onChangeText,
    onChangeTldEvent,
    onChangeSubdomainsEvent,
    onThemeChange,
    onChangeWebsiteVisiblity,
    onMobileViewPortEvent,
    onChangeHideElements,
    onChangeUA,
    onChangeUAEvent: onChangeUA,
  }
}
