import React, { memo } from 'react'
import { InputActions } from '@app/components/general'
import { InputHeaders } from './forms/input-headers'
import { useWebsiteContext } from '../providers/website'
import { WCAGSelectInput } from './select/select-input'
import { FormControl } from './form-control'
import { useAuthContext } from '../providers/auth'
import { Checkbox } from './check-box'
import { RunnerSelect } from './runner-select'
import {
  SitemapKeys,
  SiteMapOption,
  SitemapSelectInput,
} from './select/sitemap-input'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { ThemeInput } from './select/theme-input'
import { CrawlLimitBoxInput } from './inputs/crawl-limit-input'
import { BlacklistInput } from './inputs/blacklist-input'
import { ProxyBoxInput } from './inputs/proxy-input'
import { HideElementsInput } from './inputs/hide-elements-input'
import { RootSelectorInput } from './inputs/root-selector-input'
import { AgentInput } from './inputs/ua-input'
import { CrawlDelayBoxInput } from './inputs/crawl-delay-input'
import { TooltipProvider } from '@app/@/components/ui/tooltip'
import { IgnoreInput } from './select/ignore-input'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@app/@/components/ui/collapsible'
import { Separator } from '@app/@/components/ui/separator'
import { InfoBlock } from './cells'
import { Website } from '@app/types'

interface FormDialogProps {
  buttonTitle?: string | JSX.Element
  okPress?: (a: any) => void
  buttonStyles?: string
  icon?: boolean | 'add' // show btn with icon
  iconButton?: boolean
  dialogDisabled?: boolean
  translation?: string
  website?: Website
}

const checkBoxContainerStyles =
  'flex py-2.5 place-items-center min-w-[85px] gap-x-1.5'

const checkboxStyle = 'w-6 h-6'

function DashboardFormDialogWrapper({ translation, website }: FormDialogProps) {
  const { t } = useTranslation(translation || 'dashboard')
  const { account } = useAuthContext()
  const { addWebsiteForm, updateWebsite } = useWebsiteContext()

  // fields
  const customActions = addWebsiteForm.actions.customActions
  // headers
  const customHeader = addWebsiteForm.headers.customHeader

  const onMobileViewPortEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          mobile: !website?.mobile,
        },
      })
    } else {
      addWebsiteForm?.onMobileViewPortEvent()
    }
  }

  const onChangeRobotsEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          robots: !website?.robots,
        },
      })
    } else {
      addWebsiteForm?.onChangeRobotsEvent()
    }
  }

  const onChangeMonitoringEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          monitoringEnabled: !website?.monitoringEnabled,
        },
      })
    } else {
      addWebsiteForm?.onChangeMonitoringEvent()
    }
  }

  const onToggleWarnings = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          warningsEnabled: !website?.warningsEnabled,
        },
      })
    } else {
      addWebsiteForm?.onToggleWarnings()
    }
  }

  const onChangeActionsEvent = async () => {
    addWebsiteForm?.onChangeActionsEvent()
  }
  const onChangeSubdomainsEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          subdomains: !website?.subdomains,
        },
      })
    } else {
      addWebsiteForm?.onChangeSubdomainsEvent()
    }
  }
  const onChangeTldEvent = async () => {
    if (website) {
      await updateWebsite({
        variables: {
          url: website.url,
          tld: !website?.tld,
        },
      })
    } else {
      addWebsiteForm?.onChangeTldEvent()
    }
  }

  return (
    <TooltipProvider>
      <form onSubmit={addWebsiteForm.submit} noValidate className='pt-4 w-full'>
        <div className='space-y-3'>
          <div className='overflow-y-auto max-h-[50vh] gap-3 px-1 grid grid-cols-1 md:grid-cols-2'>
            <div className='overflow-hidden'>
              <h3 className='text-base font-semibold'>Scan Configurations</h3>
              <div className={`grid grid-cols-2 py-2`}>
                {account?.role === 11 ? null : (
                  <InfoBlock
                    simpleClass={checkBoxContainerStyles}
                    toolTip={t('dashboard-inputs:mobile-tip')}
                  >
                    <Checkbox
                      onChange={onMobileViewPortEvent}
                      defaultChecked={
                        website?.mobile ?? addWebsiteForm.mobileViewport
                      }
                      id={'mobile'}
                      className={checkboxStyle}
                    />
                    <FormControl htmlFor='mobile' visible>
                      {t('mobile')}
                    </FormControl>
                  </InfoBlock>
                )}

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:robots-tip')}
                >
                  <Checkbox
                    onChange={onChangeRobotsEvent}
                    id={'robots'}
                    defaultChecked={website?.robots ?? addWebsiteForm.robots}
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='robots' visible>
                    {t('robots')}
                  </FormControl>
                </InfoBlock>

                {account?.role === 11 ? null : (
                  <InfoBlock
                    simpleClass={checkBoxContainerStyles}
                    toolTip={t('dashboard-inputs:monitor-tip')}
                  >
                    <Checkbox
                      onChange={onChangeMonitoringEvent}
                      defaultChecked={
                        website?.monitoringEnabled ??
                        addWebsiteForm.monitoringEnabled
                      }
                      id={'monitoring'}
                      className={checkboxStyle}
                    />
                    <FormControl htmlFor='monitoring' visible>
                      {t('monitor')}
                    </FormControl>
                  </InfoBlock>
                )}

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:warnings-tip')}
                >
                  <Checkbox
                    onChange={onToggleWarnings}
                    defaultChecked={
                      website?.warningsEnabled ?? addWebsiteForm.warningsEnabled
                    }
                    id={'warnings'}
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='warnings' visible>
                    {t('warnings')}
                  </FormControl>
                </InfoBlock>

                {account?.role === 11 ? null : (
                  <InfoBlock
                    simpleClass={checkBoxContainerStyles}
                    toolTip={t('dashboard-inputs:actions-tip')}
                  >
                    <Checkbox
                      onChange={onChangeActionsEvent}
                      defaultChecked={
                        website?.actionsEnabled ?? addWebsiteForm.customActions
                      }
                      id={'actions'}
                      className={checkboxStyle}
                    />
                    <FormControl htmlFor='actions' visible>
                      {t('actions')}
                    </FormControl>
                  </InfoBlock>
                )}

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:headers-tip')}
                >
                  <Checkbox
                    onChange={addWebsiteForm.onChangeHeadersEvent}
                    id={'headers'}
                    defaultChecked={
                      website?.pageHeaders ?? addWebsiteForm.customHeader
                    }
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='headers' visible>
                    {t('headers')}
                  </FormControl>
                </InfoBlock>

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:subdomains-tip')}
                >
                  <Checkbox
                    defaultChecked={
                      website?.subdomains ?? addWebsiteForm.subdomains
                    }
                    onChange={onChangeSubdomainsEvent}
                    id={'subdomains'}
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='subdomains' visible>
                    {t('subdomains')}
                  </FormControl>
                </InfoBlock>

                <InfoBlock
                  simpleClass={checkBoxContainerStyles}
                  toolTip={t('dashboard-inputs:tlds-tip')}
                >
                  <Checkbox
                    onChange={onChangeTldEvent}
                    defaultChecked={website?.tld ?? addWebsiteForm.tld}
                    id={'tlds'}
                    className={checkboxStyle}
                  />
                  <FormControl htmlFor='tlds' visible>
                    {t('tlds')}
                  </FormControl>
                </InfoBlock>
              </div>
              {customHeader ? (
                <InputHeaders {...addWebsiteForm.headers} />
              ) : null}
              {customActions ? (
                <InputActions {...addWebsiteForm.actions} />
              ) : null}
            </div>
            <div>
              <h3 className='text-base font-semibold'>Site Personalizations</h3>
              <div className={`grid grid-cols-1 w-full flex-1 gap-3 py-2`}>
                <WCAGSelectInput
                  standard={website?.standard ?? addWebsiteForm.standard}
                  onStandardChange={addWebsiteForm.onStandardChange}
                  spacing
                  borderLess
                  className={'w-full flex-1'}
                />

                <SitemapSelectInput
                  sitemap={
                    SiteMapOption[
                      website?.sitemap ?? addWebsiteForm.sitemap
                    ] as SitemapKeys
                  }
                  onSitemapChange={addWebsiteForm.onChangeSitemapEvent}
                  label={t('sitemap')}
                  labelOptions={t('sitemap-options')}
                  borderLess
                  t={t}
                  className={'w-full flex-1'}
                />

                <RunnerSelect
                  cb={addWebsiteForm.onRunnerEvent}
                  borderLess
                  defaultRunners={
                    website?.runners ?? addWebsiteForm.defaultRunnerData
                  }
                  className={'w-full flex-1'}
                />

                <ThemeInput
                  theme={website?.theme ?? addWebsiteForm.theme}
                  onStandardChange={addWebsiteForm.onThemeChange}
                  spacing
                  borderLess
                  className={'w-full flex-1'}
                />
              </div>
            </div>
          </div>

          <Separator />

          <div className='py-3'>
            <Collapsible>
              <CollapsibleTrigger className='w-full border flex place-content-between px-4 py-3 rounded md:text-lg font-bold'>
                Display More Settings
                <svg
                  width='24'
                  height='25'
                  viewBox='0 0 24 25'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <title>Collaspe Icon</title>
                  <path
                    d='M3.33047 9.49973C3.33047 9.68973 3.40047 9.87973 3.55047 10.0297L10.0705 16.5497C11.1305 17.6097 12.8705 17.6097 13.9305 16.5497L20.4505 10.0297C20.7405 9.73973 20.7405 9.25973 20.4505 8.96973C20.1605 8.67973 19.6805 8.67973 19.3905 8.96973L12.8705 15.4897C12.3905 15.9697 11.6105 15.9697 11.1305 15.4897L4.61047 8.96973C4.32047 8.67973 3.84047 8.67973 3.55047 8.96973C3.41047 9.11973 3.33047 9.30973 3.33047 9.49973Z'
                    fill='#020817'
                  />
                </svg>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <div
                  className={`grid grid-cols-2 md:grid-cols-4 py-6 items-center gap-x-2 gap-y-5`}
                >
                  <AgentInput
                    onChangeUA={addWebsiteForm.onChangeUA}
                    ua={addWebsiteForm.ua}
                    id='ua-dialog'
                    activeSubscription={account.activeSubscription}
                    dialog
                  />

                  <CrawlDelayBoxInput
                    id='crawl-delay-dialog'
                    activeSubscription={account.activeSubscription}
                    value={addWebsiteForm.crawlDelay}
                    onChange={addWebsiteForm.onChangeCrawlDelay}
                    dialog
                  />
                  <RootSelectorInput
                    onChange={addWebsiteForm.onChangeRootSelector}
                    value={addWebsiteForm.rootElement}
                    id='root-dialog'
                    activeSubscription={account.activeSubscription}
                    dialog
                  />

                  <HideElementsInput
                    onChangeHideElements={addWebsiteForm.onChangeHideElements}
                    hideElements={addWebsiteForm.hideElements}
                    id='hide-dialog'
                    text={t('hide-elements')}
                    activeSubscription={account.activeSubscription}
                    dialog
                  />

                  <ProxyBoxInput
                    onChangeProxy={addWebsiteForm.onChangeProxy}
                    value={addWebsiteForm.proxy}
                    id='proxy-dialog'
                    text={t('proxy')}
                    activeSubscription={account.activeSubscription}
                    dialog
                  />

                  <CrawlLimitBoxInput
                    id='climit-dialog'
                    onChange={addWebsiteForm.onCrawlLimitChange}
                    activeSubscription={account.activeSubscription}
                    dialog
                  />

                  <BlacklistInput
                    id='blacklist-dialog'
                    blacklist={addWebsiteForm.blacklist}
                    onChangeBlacklist={addWebsiteForm.onBlacklistChange}
                    activeSubscription={account.activeSubscription}
                    dialog
                  />

                  <IgnoreInput
                    t={t}
                    onLoadRulesEvent={addWebsiteForm.onLoadRulesEvent}
                    ignoreList={addWebsiteForm.ignoreList}
                    setIgnore={addWebsiteForm.setIgnore}
                    ignore={addWebsiteForm.ignore}
                    dialog
                    tooltip
                    activeSubscription={account.activeSubscription}
                  />
                </div>
              </CollapsibleContent>
            </Collapsible>
          </div>
        </div>
      </form>
    </TooltipProvider>
  )
}

export const DashboardFormDialog = memo(DashboardFormDialogWrapper)
