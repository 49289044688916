import {
  createElement,
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
  useRef,
} from 'react'
import { useQuery, useMutation, useSubscription } from '@apollo/client'
import {
  ADD_WEBSITE,
  REMOVE_WEBSITE,
  REMOVE_PAGE,
  UPDATE_WEBSITE,
  CRAWL_WEBSITE,
  SCAN_WEBSITE,
} from '@app/mutations'
import { GET_WEBSITES, GET_WEBSITES_INFO, updateCache } from '@app/queries'
import {
  ISSUE_SUBSCRIPTION,
  CRAWL_COMPLETE_SUBSCRIPTION,
} from '@app/subscriptions'
import { AppManager, HomeManager } from '@app/managers'
import type { User, Website } from '@app/types'
import { useWasmContext } from '@app/components/providers'
import { useInteractiveContext } from '@app/components/providers/interactive'
import { FilterManager } from '@app/managers/filters'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { dataErrors } from '@app/data/static/errors'
import Trans from 'next-translate/Trans'
import { routeParseAbsolute } from '@app/lib/router-handler'
import { useRouter } from 'next/router'
import { blueLink } from '@app/components/stateless/typo/link-style'
import { ModalOptions } from '@app/components/general/cells/enums'
import { useAuthContext } from '@app/components/providers/auth'
import { SortDirection } from '@app/components/general/dashboard-website-list'
import { toast, useToast } from '@app/@/components/ui/use-toast'
import { Translate } from 'next-translate'
import { useMountEffectOnce } from '@app/lib/hooks/use-mount-effect-once'
import { WEBSITE_PAGE_LIMIT } from '@app/queries/websites'
import { useAddWebsiteForm } from '@app/data/local/useWebsiteForm'

export function arrayAverage(arr: any[] = []): number {
  let sum = 0

  for (const i in arr) {
    sum += arr[i]
  }

  return sum / (arr.length || 1)
}

type Q = { user: User & { website: Partial<Website> } }

// Extending Function with a translation method .t
interface UpdatableQueryFunction extends Function {
  (
    prev: Q,
    fetchMoreResult: {
      fetchMoreResult: Partial<Website>
      prevResult?: Partial<Website[]>
    }
  ): Partial<Website>
  t?: Translate
}

// fetch more items - todo: types
const updateQuery: UpdatableQueryFunction = (prev, { fetchMoreResult }) => {
  if (!fetchMoreResult || !fetchMoreResult?.user?.websites?.length) {
    toast({
      title:
        updateQuery.t &&
        updateQuery.t('no-more', { content: updateQuery.t('websites') }),
    })
    return prev
  }

  const uniqueWebsitesMap = new Map()

  const websites = [
    ...(prev?.user?.websites || []),
    ...(fetchMoreResult?.user?.websites || []),
  ]

  for (const website of websites) {
    if (website) {
      uniqueWebsitesMap.set(website.url, website)
    }
  }

  const uniqueWebsites = Array.from(uniqueWebsitesMap.values())

  return {
    ...prev,
    user: {
      ...prev?.user,
      websites: uniqueWebsites,
    },
  }
}

type Vars = {
  url?: string
  customHeaders?: any
  filter?: string // search filtering
  actions?: any // todo: type
}

export enum DashboardLayout {
  Group,
  List,
}

// sort a website by order
export type SortMap = Map<
  string,
  { sortDirection?: SortDirection; sortKey: string }
>

// create website steps
export enum AddWebsiteStep {
  First,
  Second,
  Third,
  Fourth,
  Fifth,
}

/*
 * This hook returns all the queries, mutations, and subscriptions between your Website with the graphs,
 * Pages, and Issues with pagination.
 * @example const {data} = useWebsiteData(); // returns websites
 * @example const {data} = useWebsiteData('', 'www.somepage.com', null, false); bind to a website for mutations.
 *
 */
export const useWebsiteData = (
  filter: string = '',
  url: string = '',
  customHeaders: any = null,
  skip: boolean = false,
  scopedQuery: string = ''
) => {
  const { t } = useTranslation('dashboard')

  useMountEffectOnce(() => {
    updateQuery.t = t
  })

  const router = useRouter()
  const { account } = useAuthContext()
  const { feed } = useWasmContext()
  const [dashboardLayout, setDashboardLayout] = useState<DashboardLayout>(
    DashboardLayout.Group
  )
  const [addWebsiteStep, setAddWebsiteStep] = useState<AddWebsiteStep>(
    AddWebsiteStep.First
  )
  const hookSignatures = useRef<Map<string, string>>(new Map())
  const [sortDirections, setSortDirection] = useState<SortMap>(new Map())
  const { setSelectedWebsite } = useInteractiveContext()
  const [_, forceUpdate] = useReducer((x) => x + 1, 0) // top level force update state
  const [activeCrawls, setActiveCrawl] = useState<
    { [key: string]: boolean } | Record<string, any>
  >({})

  const { toast } = useToast()

  const locale = router.locale
  const skipAccount = account?.role === 11 || !account.role

  const _updateCache = useMemo(() => {
    return Object.assign({}, updateCache)
  }, [])

  // get vars for querys
  const { variables, pageVars } = useMemo(() => {
    const _variables: Vars = {}
    const _pageVars: Vars & {
      limit?: number
      offset?: number
      filter?: string
    } = {
      limit: WEBSITE_PAGE_LIMIT,
      offset: 0,
    }

    if (filter) {
      _variables.filter = filter
      _pageVars.filter = filter
    }
    if (customHeaders) {
      _variables.customHeaders = customHeaders
      _pageVars.customHeaders = customHeaders
    }
    if (url) {
      _variables.url = url
      _pageVars.url = url
    }

    return {
      variables: _variables,
      pageVars: _pageVars,
    }
  }, [filter, customHeaders, url])

  // start of main queries for pages. Root gets all
  const { data, loading, refetch, error, fetchMore, client } = useQuery(
    GET_WEBSITES,
    {
      variables: pageVars,
      skip: scopedQuery !== 'websites' || skipAccount,
    }
  )

  const getWebsites = async () => {
    try {
      await refetch()
    } catch (e) {
      console.error(e)
    }
  }

  // Get Scripts Pages
  const {
    data: actionResults,
    loading: actionsDataLoading,
    fetchMore: fetchMoreActions,
  } = useQuery(GET_WEBSITES_INFO, {
    variables: pageVars,
    ssr: false,
    skip: scopedQuery !== 'actions' || skipAccount,
  })

  // bind the pageVars directly
  useEffect(() => {
    _updateCache.pageVars = pageVars
  }, [pageVars])

  const [removeWebsite, { loading: removeLoading }] = useMutation(
    REMOVE_WEBSITE,
    _updateCache
  )

  const [removePage, { loading: removePageLoading }] = useMutation(
    REMOVE_PAGE,
    _updateCache
  )

  const [addWebsiteMutation, { data: addWebsiteData, loading: addLoading }] =
    useMutation(ADD_WEBSITE, _updateCache)

  const [updateWebsite, { data: updateData }] = useMutation(UPDATE_WEBSITE, {
    variables,
  })

  // trigger events to gather issues
  const [crawl, { loading: crawlLoading }] = useMutation(CRAWL_WEBSITE, {
    onCompleted(data) {
      // set crawl in progress status
      if ((data && data.code === '202') || data.code === 202) {
        toast({
          title: data.message ?? t('crawl-inprogress'),
        })
      }
    },
  })

  const [scan, { loading: scanLoading }] = useMutation(SCAN_WEBSITE)

  // add a website to monitor
  const addWebsite = useCallback(
    async (params: any, sendToast?: boolean) => {
      try {
        const { data: ds } = (await addWebsiteMutation(params)) ?? {
          data: null,
        }

        if (ds?.addWebsite?.success && ds?.addWebsite?.website?.domain) {
          if (sendToast) {
            toast({
              title: t('crawl-starting'),
            })
          }

          setActiveCrawl((v) => ({
            ...v,
            [ds.addWebsite.website.domain]: true,
          }))

          if (HomeManager.selectedWebsite) {
            const wurl = ds?.addWebsite?.website?.url
            HomeManager.setDashboardView(wurl)
            setSelectedWebsite(wurl)
          }
        }
      } catch (e) {
        console.error(error)
      }
    },
    [setSelectedWebsite, setActiveCrawl, addWebsiteMutation, t, error, toast]
  )

  const addWebsiteForm = useAddWebsiteForm({
    translation: 'dashboard',
    addWebsite,
  })

  const websites = data?.user?.websites

  const actionsData = useMemo(
    () => actionResults?.user?.websites || [],
    [actionResults]
  )

  // website crawl finished
  const onCrawlCompleteSubscription = useCallback(
    async ({ data: crawlData }: { data: any }) => {
      const completedWebsite = crawlData?.data?.crawlComplete

      // website did not complete due to time elasped across pages
      if (completedWebsite) {
        let scoreAVG = completedWebsite?.accessScoreAverage || 0

        if (completedWebsite.shutdown) {
          AppManager.toggleSnack(
            true,
            createElement(Trans, {
              i18nKey: 'dashboard:crawl-incomplete',
              values: { url: completedWebsite.domain },
              components: [
                createElement('p'),
                createElement('a', {
                  href: routeParseAbsolute('/payments', locale),
                  className: `m-0 ${blueLink}`,
                }),
              ],
            }),
            'error',
            false,
            true
          )
        } else {
          // sort the groups when finished if not grouped
          if (!FilterManager.grouping) {
            requestAnimationFrame(FilterManager.sortGroups)
          }

          toast({
            // todo: better handle the translation completly
            title: `${t('crawl-finished')} ${completedWebsite.domain}${t(
              'crawl-finished-end'
            )} ${scoreAVG}.`,
          })

          const generateIssues = (dname: string) => {
            const pages = feed.get_website(dname)

            let websiteErrors = 0
            let websiteWarnings = 0
            let websiteNotices = 0

            const pageCount = pages?.size

            const accessScores: number[] = []

            if (pages && Array.isArray(pages)) {
              for (const [_key, value] of pages) {
                // collect website stats by iterating through pages.
                const { warningCount, errorCount, noticeCount, accessScore } =
                  value?.issuesInfo ?? {
                    warningCount: 0,
                    errorCount: 0,
                    noticeCount: 0,
                    accessScore: 0,
                  }

                accessScores.push(Number(accessScore))

                websiteErrors += Number(errorCount || 0)
                websiteWarnings += Number(warningCount || 0)
                websiteNotices += Number(noticeCount || 0)
              }
            }

            let averageItems = 0

            if (accessScores.length) {
              averageItems = arrayAverage(accessScores)
            }

            return {
              accessScoreAverage: Math.round(averageItems),
              totalIssues: websiteErrors + websiteWarnings + websiteNotices,
              // issue counters
              errorCount: websiteErrors,
              warningCount: websiteWarnings,
              noticeCount: websiteNotices,
              // amount of pages with possible issues
              pageCount,
            }
          }

          const pages = data?.user?.websites.map((website: Website) => {
            if (website.domain === completedWebsite.domain) {
              const issueInfo =
                completedWebsite.issuesInfo ?? website.issuesInfo ?? {}

              const {
                totalIssues,
                errorCount,
                warningCount,
                noticeCount,
                pageCount,
                accessScoreAverage,
              } = !completedWebsite.issuesInfo
                ? generateIssues(website.domain)
                : issueInfo

              if (!completedWebsite.issuesInfo) {
                scoreAVG = accessScoreAverage
              }

              return {
                ...website,
                crawlDuration: completedWebsite?.duration,
                scanning: false,
                issuesInfo: {
                  ...issueInfo,
                  accessScore: scoreAVG,
                  accessScoreAverage: scoreAVG,
                  totalIssues: totalIssues || 0,
                  errorCount: errorCount || 0,
                  warningCount: warningCount || 0,
                  noticeCount: noticeCount || 0,
                  pageCount: pageCount || 0,
                },
              }
            }
            return website
          })

          // Write back to the to-do list, appending the new item
          client.writeQuery({
            query: GET_WEBSITES,
            variables: pageVars,
            data: {
              user: {
                ...data?.user,
                websites: pages,
              },
            },
          })
        }

        setActiveCrawl((v) => ({
          ...v,
          [completedWebsite.domain]: false,
        }))
      }
    },
    [setActiveCrawl, t, locale, client, data, pageVars, feed, toast]
  )

  // website updated
  useEffect(() => {
    const updatedWebsite = updateData && updateData?.updateWebsite?.website

    if (updatedWebsite) {
      const dataSource = websites?.find(
        (source: Website) => source.domain === updatedWebsite?.domain
      )

      if (dataSource) {
        if (updatedWebsite?.pageHeaders) {
          client.writeQuery({
            query: GET_WEBSITES,
            variables: pageVars,
            data: {
              user: {
                ...data?.user,
                websites: websites.map((source: Website) => {
                  if (source.domain === updatedWebsite?.domain) {
                    return {
                      ...source,
                      pageHeaders: updatedWebsite.pageHeaders,
                    }
                  }
                  return source
                }),
              },
            },
          })
        }

        if (
          updatedWebsite?.webhooks &&
          !Object.is(dataSource?.webhooks ?? {}, updatedWebsite?.webhooks ?? {})
        ) {
          try {
            // validate hook pop up. TODO: remove from effect
            if (
              !hookSignatures.current.has(dataSource.domain) ||
              (hookSignatures.current.has(dataSource.domain) &&
                hookSignatures.current.get(dataSource.domain) !==
                  JSON.stringify(updatedWebsite?.webhooks ?? {}))
            ) {
              hookSignatures.current.set(
                dataSource.domain,
                JSON.stringify(updatedWebsite?.webhooks ?? {})
              )

              AppManager.clickOpen(
                updatedWebsite.webhooks,
                ModalOptions.Webhooks,
                updatedWebsite.url,
                false
              )
            }
          } catch (e) {
            console.error(e)
          }
        }

        // todo: remove hard state updates
        forceUpdate()
      }
    }
  }, [
    websites,
    data,
    updateData,
    forceUpdate,
    pageVars,
    hookSignatures,
    client,
  ])

  useSubscription(CRAWL_COMPLETE_SUBSCRIPTION, {
    onData: onCrawlCompleteSubscription,
  })

  // added new website data display alert
  useEffect(() => {
    if (addWebsiteData && !addWebsiteData?.addWebsite?.success) {
      const message = addWebsiteData.addWebsite?.message

      AppManager.toggleSnack(
        true,
        message,
        'message',
        false,
        message in dataErrors
      )
    }
  }, [addWebsiteData])

  const onIssueSubscription = useCallback(
    ({ data: subIssueData }: any) => {
      const newIssue = subIssueData?.data?.issueAdded
      const issuesInfo = newIssue?.issuesInfo

      setTimeout(() => {
        feed?.insert_website({
          domain: newIssue.domain,
          pageUrl: newIssue.pageUrl || newIssue.url,
          issues: newIssue.issue,
          issuesInfo: issuesInfo
            ? {
                accessScore: issuesInfo.accessScore || 0,
                totalIssues: issuesInfo.totalIssues || 0,
                errorCount: issuesInfo.errorCount || 0,
                warningCount: issuesInfo.warningCount || 0,
                noticeCount: issuesInfo.noticeCount || 0,
                // TODO: remove CDN handling
                possibleIssuesFixedByCdn: 0,
                issuesFixedByCdn: 0,
                issueMeta: {
                  skipContentIncluded: true,
                },
              }
            : null,
          pageLoadTime: newIssue.pageLoadTime
            ? {
                duration: newIssue.pageLoadTime.duration,
              }
            : null,
        })
      })
    },
    [feed]
  )

  const { data: issueSubData } = useSubscription(ISSUE_SUBSCRIPTION, {
    onData: onIssueSubscription,
    skip,
  })

  // full domain crawl for reports
  const crawlWebsite = useCallback(
    async (params: any) => {
      let crawling = null

      try {
        crawling = await crawl(params)
      } catch (e) {
        console.error(e)
      }

      // set the hostname to the active crawl list
      if (crawling) {
        let domain = ''

        try {
          domain = new URL(params.variables.url).hostname
        } catch (e) {
          console.error(e)
        }

        setActiveCrawl((v) => ({
          ...v,
          [domain]: true,
        }))
      }
    },
    [setActiveCrawl, crawl]
  )

  const singlePageScan = async (params: any) => {
    try {
      const scanData = await scan(params)
      return scanData?.data?.scanWebsite?.website
    } catch (e) {
      console.error(e)
    }
  }

  // dashboard page pagination
  const onLoadMoreWebsites = useCallback(async () => {
    const prevResult = data?.user?.websites
    const nextVars = {
      ...pageVars,
      offset: Number(prevResult.length || 0),
    }

    try {
      await fetchMore({
        query: GET_WEBSITES,
        variables: nextVars,
        updateQuery,
      })

      forceUpdate()
    } catch (e) {
      console.error(e)
    }
  }, [data, fetchMore, forceUpdate, pageVars])

  // actions page pagination
  const onLoadMoreActions = useCallback(async () => {
    if (!actionsDataLoading) {
      try {
        await fetchMoreActions({
          query: GET_WEBSITES_INFO,
          variables: {
            offset: Number(actionsData.length || 0),
          },
          updateQuery,
        })
        forceUpdate()
      } catch (e) {
        console.error(e)
      }
    }
  }, [actionsData, fetchMoreActions, actionsDataLoading, forceUpdate])

  const removeActiveCrawl = useCallback(
    (domain: string) => {
      setActiveCrawl((v) => ({
        ...v,
        [domain]: false,
      }))
    },
    [setActiveCrawl]
  )

  const onSortEvent = useCallback(
    (domain: string, direction?: SortDirection, sortKey?: string) => {
      setSortDirection((m) => {
        let map = new Map(m)
        map.set(domain, { sortDirection: direction, sortKey: sortKey || '' })

        let target_url = domain

        try {
          target_url = new URL(domain).hostname
        } catch (e) {
          console.error(e)
        }

        // prob should have a clear sort method
        if (direction) {
          feed.sort_website_direction(
            target_url,
            direction || '',
            sortKey || 'errorCount'
          )
        } else if (!direction && !sortKey) {
          // todo: perform clear sort from wasm

          feed.sort_website(target_url)
        }

        forceUpdate()

        return map
      })
    },
    [setSortDirection, feed, forceUpdate]
  )

  return {
    subscriptionData: {
      issueSubData,
    },
    data: websites, // TODO: rename to websites.
    actionsData, // [scopred] collection of actions
    actionsDataLoading, // [scoped] issues loading
    loading,
    onSortEvent,
    error, // general mutation error
    mutatationLoading:
      removeLoading ||
      addLoading ||
      crawlLoading ||
      scanLoading ||
      removePageLoading,
    // other state
    activeCrawls,
    // mobile feed open
    feedOpen: addWebsiteForm.open,
    removeWebsite,
    addWebsite,
    removePage,
    refetch,
    crawlWebsite,
    singlePageScan, // single page web scan
    updateWebsite,
    setIssueFeedContent: addWebsiteForm.setOpen,
    // pagination
    onLoadMoreWebsites,
    onLoadMoreActions,
    removeActiveCrawl,
    // forceupdate
    forceUpdate,
    // lazy queries
    getWebsites,
    sortDirections,
    dashboardLayout,
    setDashboardLayout,
    // add form
    addWebsiteStep,
    setAddWebsiteStep,
    // add website hook
    addWebsiteForm,
  }
}
